// src/components/Footer/Footer.jsx
import { MdBusinessCenter } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail, MdPlace } from "react-icons/md";
import { FaLinkedin, FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Footer() { // Muutettu nimi App -> Footer
  return (
    <footer className="font-customFont1 text-darkest dark:text-primary text-center lg:text-left dark:bg-violet bg-primary" aria-label="Alatunniste">
      
      {/* Sosiaalisen median osio */}
      <div className="flex items-center justify-center border-b-2 border-primary p-5" aria-labelledby="sosiaalinen-media">
        <h2 id="sosiaalinen-media" className="mr-5 font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-lg sm:text-1xl md:text-1xl lg:text-2xl xl:text-3xl 3xl:text-3xl font-normal">
          Seuraa meitä sosiaalisessa mediassa
        </h2>
        
        {/* Sosiaalisen median linkit */}
        <div className="myreact-icons flex justify-center gap-5">
          <a href="https://www.facebook.com/spiderlegsproductions/" target="_blank" rel="noopener noreferrer" aria-label="Facebookissa" title="Seuraa Facebookissa!">
            <FaFacebook size={30} />
          </a>
          <a href="https://www.instagram.com/spiderlegsproductions/" target="_blank" rel="noopener noreferrer" aria-label="Instagramissa" title="Seuraa Instagramissa!">
            <FaInstagram size={30} />
          </a>
          <a href="https://www.linkedin.com/in/spiderlegs-productions/" target="_blank" rel="noopener noreferrer" aria-label="LinkedInissä" title="Seuraa LinkedInissä!">
            <FaLinkedin size={30} />
          </a>
          <a href="https://www.tiktok.com/@spiderlegsproductions" target="_blank" rel="noopener noreferrer" aria-label="TikTokissa" title="Seuraa TikTokissa!">
            <FaTiktok size={30} />
          </a>
        </div>
      </div>

      {/* Yhteystiedot ja navigointi */}
      <div className="mx-5 text-center md:text-left sm:text-left mdd:text-left lg:text-left xl:text-left 2xl:text-left font-customFont1 text-sm sm:text-lg md:text-1xl lg:text-2xl text-gray-400" aria-labelledby="yhteystiedot-navigointi">
        <h2 id="yhteystiedot-navigointi" className="sr-only">Yhteystiedot ja navigointi</h2>
        <div className="grid grid-cols-1 xsm:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          
          {/* Yhteystiedot */}
          <div className='pt-4' aria-labelledby="kontakti-otsikko">
            <h3 id="kontakti-otsikko" className="mb-4 flex justify-center font-semibold uppercase sm:justify-start md:justify-start mdd:justify-start lg:justify-start xl:justify-start 2xl:justify-start text-darkest dark:text-primary">
              Kontakti
            </h3>
            <p title="Sijainti" className="mb-2 flex items-center justify-center sm:justify-start md:justify-start mdd:justify-start lg:justify-start xl:justify-start 2xl:justify-start text-darkest dark:text-primary" aria-label="Sijaintimme Kerava, Savio, FI">
              <MdPlace size={20} />&nbsp; Kerava, Savio, FI
            </p>
            <p title="Sähköposti" className="mb-2 flex items-center justify-center sm:justify-start md:justify-start mdd:justify-start lg:justify-start xl:justify-start 2xl:justify-start text-darkest dark:text-primary">
              <MdOutlineMail size={20} />
              <a href="mailto:info@spiderlegsproductions.com?body=Kirjoita tähän sähköpostisi.." aria-label="Sähköposti: info@spiderlegsproductions.com">&nbsp; info@spiderlegsproductions.com</a>
            </p>
            <p title="Puhelinnumero" className="mb-2 flex items-center justify-center sm:justify-start md:justify-start mdd:justify-start lg:justify-start xl:justify-start 2xl:justify-start text-darkest dark:text-primary">
              <FaPhoneAlt size={20} />&nbsp; +358 44 351 92 65
            </p>
            <p title="Y-tunnus" className="flex items-center justify-center sm:justify-start md:justify-start mdd:justify-start lg:justify-start xl:justify-start 2xl:justify-start text-darkest dark:text-primary">
              <MdBusinessCenter size={20} />&nbsp; Y-tunnus 3214378-6
            </p>
            <p className='flex items-center justify-center sm:justify-start md:justify-start mdd:justify-start lg:justify-start xl:justify-start 2xl:justify-start py-4'>
              <Link to="/tietosuojalausunto" title="Tietosuojalausunto" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}}>Tietosuojalausunto</Link>
            </p>
          </div>

          {/* Navigointi */}
          <div className="pt-4" aria-labelledby="navigointi-otsikko">
            <h3 id="navigointi-otsikko" className="sr-only">Navigointi</h3>
            <a title="Klikkaa etusivulle!" className="text-3xl font-bold font-customFont2 text-darkest dark:text-primary mb-2 flex items-center justify-center sm:justify-start md:justify-start mdd:justify-start lg:justify-start xl:justify-start 2xl:justify-start" href="https://spiderlegsproductions.com/" target="_blank" rel="noopener noreferrer">
              Spiderlegs Productions
            </a>
            <p className="font-customFont1 text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal" aria-hidden="true">
              NAVIGOINTI
            </p>
            <nav aria-label="Päävalikko">
              <Link to="/" title="Koti" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}}>Koti</Link><br  />
              <Link to="/palvelut" title="Palvelut" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}}>Palvelut</Link><br />
              <Link to="/meista" title="Meistä" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}}>Meistä</Link><br />
              <Link to="/UKK" title="UKK" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}}>UKK</Link><br />
              <Link to="/varauskalenteri" title="Ota yhteyttä" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}}>Varauskalenteri</Link><br /><br />
            </nav>
          </div>
        </div>
      </div>

      {/* Copyright section */}
      <div className="font-customFont1 p-2 text-center dark:bg-dark bg-lightbg" aria-labelledby="copyright">
        <h3 id="copyright" className="sr-only">Copyright-osio</h3>
        <span>Copyright © 2024 </span>
        <a title="Katso tekijän verkkosivut" className="font-semibold text-darkest dark:text-primary" href="https://arttustenius.com/" rel="noopener noreferrer">
          Arttu Stenius.
        </a> Kaikki oikeudet pidetään.
      </div>
    </footer>
  );
}
