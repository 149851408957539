// src/components/LightRental/LightRental.jsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextToSpeech from "../../TextToSpeech/textToSpeech";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import AOS from "aos";
import "aos/dist/aos.css"; // Importoi AOS CSS

// Apufunktiot ostoskorin hallintaan
const getCart = () => {
  return JSON.parse(localStorage.getItem("rentalCart")) || [];
};

const isProductInCart = (productId, cartItems) => {
  return cartItems.some((item) => item.id === productId);
};

const getProductQuantityInCart = (productId, cartItems) => {
  return cartItems
    .filter((item) => item.id === productId)
    .reduce((sum, item) => sum + item.quantity, 0);
};

const text =
  "Esitystekniikan vuokraus. Äänentoisto on vasta alkua! Nosta tapahtumasi seuraavalle tasolle upeilla valaistus- ja savutehosteilla. Oikeanlainen esitystekniikka luo unohtumattoman tunnelman ja tekee vaikutuksen yleisöösi.";

const LightRental = () => {
  const [lightRentalData, setLightRentalData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedQuantities, setSelectedQuantities] = useState({}); // Tallentaa valitut määrät
  const [cartItems, setCartItems] = useState(getCart()); // Ostoskorin tuotteet

  // Funktio esitystekniikan vuokrausten hakemiseen
  const fetchLightRentals = async () => {
    try {
      const category = encodeURIComponent("Esitystekniikka"); // Varmista oikea kirjainkoko
      const response = await fetch(
        `https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=${category}`
      );

      if (!response.ok) {
        throw new Error(`Palvelin palautti virheen: ${response.status}`);
      }

      const data = await response.json();

      if (
        data.success &&
        data.categories &&
        Array.isArray(data.categories["Esitystekniikka"])
      ) {
        setLightRentalData(data.categories["Esitystekniikka"]);
      } else {
        throw new Error("API:n vastausrakenne ei ole odotettu.");
      }
    } catch (err) {
      console.error("Virhe tietojen haussa:", err);
      setError("Tietojen lataus epäonnistui. Yritä myöhemmin uudelleen.");
      // toast.error("Tietojen lataus epäonnistui. Yritä myöhemmin uudelleen.", { position: "top-right" });
    }
  };

  // Lisää tuote koriin
  const addToCart = async (product) => {
    try {
      const quantityToAdd = selectedQuantities[product.id] || 1; // Käytä valittua määrää tai oletus 1

      // Hae varastotilanne uudelleen varmistaaksesi tuoreimman tiedon
      const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
      if (!response.ok) {
        throw new Error(`Varaston haku epäonnistui: ${response.status}`);
      }
      const data = await response.json();
      const inventory = Object.values(data.categories).flat();
      const currentProduct = inventory.find((item) => item.id === product.id);
      if (!currentProduct) {
        // toast.error(`Tuotetta ${product.name} ei löytynyt varastosta.`, { position: "top-right" });
        return;
      }
      const productStock = Number(currentProduct.available);

      // Hae ostoskorin nykyinen määrä
      const currentQuantityInCart = getProductQuantityInCart(product.id, cartItems);

      // Laske jäljellä oleva varasto
      const availableTotal = productStock - currentQuantityInCart;

      // Tarkista, ettei lisättävä määrä ylitä varastoa
      if (quantityToAdd > availableTotal) {
        // toast.error(
        //   `Tuotetta ${product.name} ei voi lisätä enempää koriin kuin mitä varastossa on (${availableTotal} kpl).`,
        //   { position: "top-right" }
        // );
        return;
      }

      // Lisää tai päivitä tuote koriin
      const updatedCart = [...cartItems];
      const existingProduct = updatedCart.find(
        (item) => item.id === product.id
      );
      if (existingProduct) {
        existingProduct.quantity += quantityToAdd;
      } else {
        updatedCart.push({
          id: product.id,
          name: product.name,
          price: product.price,
          quantity: quantityToAdd,
          category: "Esitystekniikka", // Lisää kategoriakenttä
        });
      }

      // Päivitä localStorage
      localStorage.setItem("rentalCart", JSON.stringify(updatedCart));

      // Päivitä cartItems state
      setCartItems(updatedCart);

      // Kerro muille komponenteille, että ostoskoria on päivitetty
      window.dispatchEvent(new Event("cartUpdated"));

      // Ilmoita onnistuneesta lisäyksestä
      // toast.success(`${quantityToAdd} kpl ${product.name} lisätty vuokrauskoriin!`);
    } catch (error) {
      console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
      // toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin.", { position: "top-right" });
    }
  };

  // Käsittelee määrän lisäämisen
  const incrementQuantity = (productId, available) => {
    setSelectedQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 1;
      if (currentQuantity < available) {
        return { ...prevQuantities, [productId]: currentQuantity + 1 };
      }
      return prevQuantities;
    });
  };

  // Käsittelee määrän vähentämisen
  const decrementQuantity = (productId) => {
    setSelectedQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 1;
      if (currentQuantity > 1) {
        return { ...prevQuantities, [productId]: currentQuantity - 1 };
      }
      return prevQuantities;
    });
  };

  useEffect(() => {
    // Alusta AOS
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });

    fetchLightRentals();

    // Kuuntele ostoskorin päivityksiä
    const handleCartUpdate = () => {
      setCartItems(getCart());
    };

    window.addEventListener("cartUpdated", handleCartUpdate);
    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, []); // Poistettu cartItems riippuvuus

  useEffect(() => {
    console.log("Light Rental Data:", lightRentalData);
  }, [lightRentalData]);

  useEffect(() => {
    console.log("Cart Items:", cartItems);
  }, [cartItems]);

  return (
    <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 pl-5 pr-5 rounded-3xl cursor-pointer p-5 my-10">
      <div className="container mx-auto">

        {/* Sivun otsikko */}
        <div className="flex flex-row items-center flex-wrap">
          <h2 className="text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight text-left font-customFont2 text-darkest dark:text-primary text-shadow-lg">
            ESITYSTEKNIIKAN VUOKRAUS
          </h2>

          <div className="speech-title ml-5 flex justify-center">
            <TextToSpeech text={text} />
          </div>
        </div>

        {/* Sivun kuvaus */}
        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
          Äänentoisto on vasta alkua! Nosta tapahtumasi seuraavalle tasolle upeilla valaistus- ja savutehosteilla. Oikeanlainen esitystekniikka luo unohtumattoman tunnelman ja tekee vaikutuksen yleisöösi.
        </p>

        {/* Ominaisuudet */}
        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl p-4 pb-10">
          <li>
            <strong>Kotibileet:</strong> Pienet ja kätevät äänentoistopaketit, jotka sopivat täydellisesti musiikin kuunteluun ja tunnelman luomiseen kotibileissä.
          </li>
          <li>
            <strong>Juhlat ja tapahtumat:</strong> Tehokkaammat äänentoistopaketit, jotka sopivat isompiin juhliin ja tapahtumiin, kuten syntymäpäiväjuhliin, häihin ja yritystapahtumiin.
          </li>
          <li>
            <strong>Bändit ja livemusiikki:</strong> Ammattitason äänentoistoratkaisut, jotka täyttävät kaikki vaatimukset ja takaavat laadukkaan äänentoiston bändien ja muiden live-esiintyjien keikoilla.
          </li>
        </ul>

        {/* Virheilmoitus */}
        {error ? (
          <div className="text-red-500 font-bold text-center mt-5">{error}</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {lightRentalData.map((item) => {
              // Muuttujat, jotka lasketaan ennen JSX:n palauttamista
              const isInCart = isProductInCart(item.id, cartItems);
              const quantityInCart = getProductQuantityInCart(item.id, cartItems);
              const availableTotal = item.available - quantityInCart;

              // LED-valoille
              const isLed = item.name.toLowerCase().includes("led");

              return (
                <div
                  key={item.id}
                  className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between"
                  data-aos="fade-up"
                >
                  <TextToSpeech text={item.description || "Ei kuvausta saatavilla."} />
                  <div className="w-full h-[120px]">
                    <Link
                      to={item.link || "#"}
                      title={item.name}
                      onClick={() => {
                        if (item.link) {
                          window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                        }
                      }}
                      aria-label={`Näytä ${item.name} tuotekuvaus`}
                    >
                      <img
                        src={item.image || "assets/images/placeholder.jpg"}
                        alt={item.name || "Ei kuvaa saatavilla"}
                        title={item.name || "Ei kuvaa saatavilla"}
                        loading="lazy"
                        className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]"
                      />
                    </Link>
                  </div>

                  <div className="flex flex-col justify-between flex-grow">
                    <div className="grid grid-cols-1 place-items-center pt-10">
                      <h3
                        className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5"
                        title={item.name}
                      >
                        {item.name || "Tuntematon tuote"}
                      </h3>
                    </div>

                    {/* Näytä varastotiedot vain LED-valojen kohdalla */}
                    {isLed && (
                      <div className="text-center mb-2">
                        {availableTotal > 0 ? (
                          <p className="font-customFont1 text-darkest dark:text-primary text-shadow-lg text-lg font-normal">
                            Vuokrattavissa: {availableTotal}
                          </p>
                        ) : (
                          <p className="font-customFont1 text-darkest dark:text-primary text-shadow-lg text-lg font-normal">
                            Vuokrattavissa: 0
                          </p>
                        )}
                      </div>
                    )}

                    <div className="mt-auto text-center">
                      <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                        <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal">
                          {item.price ? `${item.price} € / 24h` : "Hinta ei saatavilla"}
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <Link
                          to={item.link || "#"}
                          title="Katso lisätiedot"
                          onClick={() => {
                            if (item.link) {
                              window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                            }
                          }}
                          aria-label={`Katso lisätiedot ${item.name}`}
                        >
                          <button
                            className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75"
                            title="Katso lisätiedot"
                          >
                            Lisätiedot
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* Lisää "Lisää koriin" -nappi ja määrävalitsin */}
                  {isLed ? (
                    <div className="grid place-content-center mt-4">
                      <div className="flex flex-col items-center space-y-2">
                        {/* Näytä määrän valitsin vain, jos availableTotal > 0 */}
                        {availableTotal > 0 && (
                          <div className="flex items-center justify-center space-x-2">
                            {/* Vähennä määrää */}
                            <button
                              onClick={() => decrementQuantity(item.id)}
                              className="h-8 w-8 rounded-full bg-darkest text-primary font-bold flex items-center justify-center hover:bg-blue-900 dark:bg-violet dark:text-primary dark:hover:bg-blue-900"
                              title="Vähennä määrää"
                            >
                              -
                            </button>

                            {/* Näytä valittu määrä */}
                            <span className="text-xl font-bold text-darkest dark:text-primary">
                              {selectedQuantities[item.id] || 1}
                            </span>

                            {/* Lisää määrää */}
                            <button
                              onClick={() => incrementQuantity(item.id, availableTotal)}
                              className="h-8 w-8 rounded-full bg-darkest text-primary font-bold flex items-center justify-center hover:bg-blue-900 dark:bg-violet dark:text-primary dark:hover:bg-blue-900"
                              title="Lisää määrää"
                            >
                              +
                            </button>
                          </div>
                        )}

                        {/* Lisää koriin -nappi */}
                        <button
                          title={availableTotal <= 0 ? "Lisätty koriin" : "Lisää vuokrauskoriin"}
                          onClick={() => addToCart(item)}
                          disabled={availableTotal <= 0}
                          className={`h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                            availableTotal <= 0 ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                          style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                        >
                          {availableTotal <= 0 ? "Lisätty koriin" : "Lisää koriin"}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center mt-4">
                      {/* Lisää koriin -nappi kaikille muille tuotteille */}
                      <button
                        title={isInCart ? "Lisätty koriin" : "Lisää vuokrauskoriin"}
                        onClick={() => addToCart(item)}
                        disabled={isInCart || item.available <= 0}
                        className={`h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                          isInCart || item.available <= 0
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                      >
                        {isInCart ? "Lisätty koriin" : "Lisää koriin"}
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LightRental;
