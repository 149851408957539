import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Importoi kuvat
import Chauvet from '../../assets/chauvet_logo.webp';
import img1 from '../../assets/LED-valot Chauvet 56-24C.webp';
import img2 from '../../assets/LED-valot Chauvet 56-24C2.webp';

// Import ThemeContext
import ThemeContext from '../../context/ThemeContext';

// Avustavat funktiot ostoskorin hallintaan
const getCart = () => {
    return JSON.parse(localStorage.getItem("rentalCart")) || [];
};

const getProductQuantityInCart = (productId) => {
    const cart = getCart();
    return cart
        .filter((item) => item.id === productId)
        .reduce((sum, item) => sum + item.quantity, 0);
};

const LEDLights = () => {
      const { theme } = useContext(ThemeContext); // Käytetään ThemeContext
    const [activeImg, setActiveImage] = useState(img1);
    const [available, setAvailable] = useState(0); // Määritellään 'available' tila
    const [quantity, setQuantity] = useState(1); // Valittu määrä
    const productId = "9"; // Tuotteen ID

    const calculateTotalPrice = () => {
        return quantity * 10; // Oletetaan, että hinta per kappale on 10 €
    };

    // Tekstit
    const textIntro =
        "Tähän osioon on koottu muita tuotteita tai palveluita, joita myymme tai tarjoamme vuokralle. Esittelyssä ensimmäisenä hauska Fujifilm Instax Mini-12 -kompaktikamera.";

    const textFeatures =
        "Valojen ominaisuudet. Ääniohjatut valo-ohjelmat: Voit antaa musiikin ohjata valaistusta ja luoda upeita visuaalisia tehosteita. Manuaalisesti säädettävät värit: Voit valita itse haluamasi värit ja luoda juuri oikeanlaisen tunnelman tapahtumaasi. Tehokkaat LED-valot: kahdeksan punaista, kahdeksan vihreää ja kahdeksan sinistä diodia takaavat kirkkaan ja värikkään valaistuksen. Suuri kirkkaus. Valot ovat erittäin kirkkaat ja ne sopivat myös isojen tilojen valaisemiseen. Alhainen lämmöntuotanto ja energiankulutus. LED-valot ovat energiatehokkaita ja ne eivät kuumene käytössä. Kuljetuslaatikko pyörillä ja vetokahvalla.";

    const textPrice =
        "Hinta. Alkaen viisikymmentä euroa vuorokaudessa. Tiedustele saatavuutta painamalla alla olevaa nappia.";

    // Hae tuotteen saatavuus backendistä
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
                const data = await response.json();

                if (!data.categories) {
                    throw new Error("Categories field is missing in API response");
                }

                // Combine all categories into one array
                const inventory = Object.values(data.categories).flat();

                // Find product by ID
                const currentProduct = inventory.find(item => item.id === productId);

                if (currentProduct && Object.prototype.hasOwnProperty.call(currentProduct, 'available')) {
                    const availableNumber = typeof currentProduct.available === 'number' 
                        ? currentProduct.available 
                        : parseInt(currentProduct.available, 10);
                    const currentQuantityInCart = getProductQuantityInCart(productId);
                    const finalAvailable = isNaN(availableNumber) ? 0 : availableNumber - currentQuantityInCart;
                    setAvailable(Math.max(finalAvailable, 0));

                    if (finalAvailable <= 1) {
                        setQuantity(1); // Automatically set quantity to 1
                    }
                } else {
                    console.error(`Product not found with ID ${productId} or 'available' field is missing!`);
                    // toast.error("Tuotteen saatavuustiedot puuttuvat. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            } catch (error) {
                console.error("Error fetching API data:", error);
                // toast.error("Virhe saatavuuden hakemisessa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    // Kuuntele ostoskorin päivityksiä
    useEffect(() => {
        const handleCartUpdate = () => {
            const fetchAvailability = async () => {
                try {
                    const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
                    const data = await response.json();

                    if (!data.categories) {
                        throw new Error("Categories field is missing in API response");
                    }

                    const inventory = Object.values(data.categories).flat();
                    const currentProduct = inventory.find(item => item.id === productId);

                    if (currentProduct) {
                        const productStock = Number(currentProduct.available);
                        const currentQuantityInCart = getProductQuantityInCart(productId);
                        const finalAvailable = productStock - currentQuantityInCart;
                        setAvailable(Math.max(finalAvailable, 0));
                        // Adjust quantity if necessary
                        if (quantity > finalAvailable) {
                            setQuantity(Math.min(Math.max(1, finalAvailable), finalAvailable));
                        }
                    } else {
                        setAvailable(0);
                        // toast.error("Tuotteen saatavuustiedot puuttuvat. Yritä myöhemmin uudelleen.", { position: "top-right" });
                    }
                } catch (error) {
                    console.error("Error updating availability:", error);
                    // toast.error("Virhe saatavuuden päivittämisessä. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            };

            fetchAvailability();
        };

        window.addEventListener("cartUpdated", handleCartUpdate);
        return () => {
            window.removeEventListener("cartUpdated", handleCartUpdate);
        };
    }, [productId, quantity]);

    // AddToCart function
    const addToCart = async () => {
        try {
            // Hae varastotilanne uudelleen varmistaaksesi tuoreimman tiedon
            const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const inventory = Object.values(data.categories).flat();
            const currentProduct = inventory.find(item => item.id === productId);
            const productStock = Number(currentProduct?.available) || 0;

            // Hae ostoskorin nykyinen määrä
            const cart = getCart();
            const existingProduct = cart.find(item => item.id === productId);
            const currentQuantityInCart = existingProduct ? existingProduct.quantity : 0;

            // Laske jäljellä oleva varasto
            const availableTotal = productStock - currentQuantityInCart;

            // Tarkista, ettei lisättävä määrä ylitä varastoa
            if (quantity > availableTotal) {
                // toast.error(`Valittu määrä ylittää saatavilla olevan varaston! (${availableTotal} kpl jäljellä)`, { position: "top-right" });
                return;
            }

            // Lisää tai päivitä tuote koriin
            if (existingProduct) {
                existingProduct.quantity += quantity;
                existingProduct.totalPrice = existingProduct.quantity * existingProduct.price;

            } else {
                cart.push({
                    id: productId,
                    name: "LED-valot Chauvet 56-24C",
                    price: 10, // Yksikköhinta
                    quantity: quantity,
                    totalPrice: quantity * 10, // Kokonaiskustannukset
                });
              
            }

            // Päivitä localStorage
            localStorage.setItem("rentalCart", JSON.stringify(cart));
      

            // Kerro muille komponenteille, että ostoskoria on päivitetty
            window.dispatchEvent(new Event("cartUpdated"));

            // Päivitä jäljellä oleva saatavuus oikein
            setAvailable(Math.max(availableTotal - quantity, 0));

            // Toast ilmoitus
            // toast.success(`${quantity} kpl ${currentProduct.name} lisätty vuokrauskoriin!`, {
            //     position: "bottom-left",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });

        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            // toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin.", { position: "top-right" });
        }
    };

    return (
            <>
                {/* <ToastContainer
                    toastClassName={() =>
                        "relative flex items-center justify-between p-6 mb-4 w-full max-w-full dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest text-xl font-semibold rounded-lg shadow-lg border border-gray-300"
                    }
                /> */}

                {/* Takaisin-painike */}
                <button 
                    onClick={() => { window.history.back(); }} 
                    className={`h-12 w-32 ${
                        theme === 'dark' ? 'dark:bg-violet dark:text-primary dark:hover:border-2 dark:border-primary' : 'bg-primary text-darkest hover:border-2 border-darkest'
                    } font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none`}
                    aria-label="Takaisin"
                >
                    Takaisin
                </button>
                   
                {/* Tuotteen kuvat */}
                <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                    <div className='flex flex-col gap-5'>
                        <img 
                            src={activeImg} 
                            title="LED-valot Chauvet 56-24C" 
                            alt='LED-valot Chauvet 56-24C' 
                            className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
                        />

                        <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                            <button 
                                onClick={() => handleImageClick(img1)} 
                                className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                                aria-label="Näytä LED-valot Chauvet 56-24C Front View"
                            >
                                <img 
                                    src={img1} 
                                    alt="LED-valot Chauvet 56-24C Front View" 
                                    title="LED-valot Chauvet 56-24C Front View" 
                                    className='w-full h-full object-cover rounded-md'
                                />
                            </button>

                            <button 
                                onClick={() => handleImageClick(img2)} 
                                className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                                aria-label="Näytä LED-valot Chauvet 56-24C Side View"
                            >
                                <img 
                                    src={img2} 
                                    alt="LED-valot Chauvet 56-24C Side View" 
                                    title="LED-valot Chauvet 56-24C Side View" 
                                    className='w-full h-full object-cover rounded-md'
                                />
                            </button>
                        </div>

                        {/* Tuotteen videot */}
                        <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
                            Videot
                        </h2>
                        <div className="flex gap-5 flex-wrap justify-center aspect-video">
                            <iframe
                                className="aspect-auto"
                                src="https://www.youtube.com/embed/rTkP4k3twK0?si=ih7ZY1o_M15Q34pJ"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="LED-valot Chauvet 56-24C äänentoistopaketti esittelyvideo"
                                aria-label="Katso LED-valot Chauvet 56-24C äänentoistopaketti esittelyvideo"
                            ></iframe>
                        </div>
                    </div>

                    {/* Tuotteen kuvaus */}
                    <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                        <div>
                            {/* Otsikko ja TextToSpeech */}
                            <div className="flex flex-row items-center">
                                <h1 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none'>
                                    LED-valot Chauvet 56-24C 6kpl
                                </h1>
                                <div className='speech-title ml-5 flex justify-center'>
                                    <TextToSpeech text={textIntro} />
                                </div>    
                            </div>
                            
                            <br />

                            {/* Teksti */}
                            <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-light'>
                                Näillä LED-valoilla saa luotua upeaa tunnelmaa ja näyttävän valoshow juhlatilaan.
                            </p>
                            <br />
                            <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                Nämä monipuoliset valot sopivat täydellisesti tunnelman luomiseen kaikenlaisissa tiloissa.
                            </p>
                            <br />

                            {/* Valojen ominaisuudet ja TextToSpeech */}
                            <div className="flex flex-row items-center">
                                <h2 className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-bold'>
                                    Valojen ominaisuudet:
                                </h2>
                                <div className='speech-title ml-5'>
                                    <TextToSpeech text={textFeatures} />
                                </div>
                            </div>

                            {/* Lista ominaisuuksista */}
                            <ul className="ominaisuudet list-disc font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-normal p-4">
                                <li><strong>Ääniohjatut valo-ohjelmat:</strong> Voit antaa musiikin ohjata valaistusta ja luoda upeita visuaalisia tehosteita.</li>
                                <li><strong>Manuaalisesti säädettävät värit:</strong> Voit valita itse haluamasi värit ja luoda juuri oikeanlaisen tunnelman tapahtumaasi.</li>
                                <li><strong>Tehokkaat LED-valot:</strong> 8 punaista, 8 vihreää ja 8 sinistä diodia takaavat kirkkaan ja värikkään valaistuksen.</li>
                                <li><strong>Suuri kirkkaus:</strong> Valot ovat erittäin kirkkaat ja ne sopivat myös isojen tilojen valaisemiseen.</li>
                                <li><strong>Alhainen lämmöntuotanto ja energiankulutus:</strong> LED-valot ovat energiatehokkaita ja ne eivät kuumene käytössä.</li>
                                <li>Kuljetuslaatikko pyörillä ja vetokahvalla</li>
                            </ul>

                            {/* Lisäsyyt ja TextToSpeech */}
                            <div className="flex flex-row items-center">
                                <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                    Tässä vielä muutama syy, miksi sinun pitäisi valita tämä paketti tapahtumaasi:
                                </p>
                                <div className='speech-title ml-5'>
                                    <TextToSpeech text={textPrice} />
                                </div>
                            </div>

                            {/* Lista lisäsyistä */}
                            <ul className="ominaisuudet list-disc font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-normal p-4">
                                <li><strong>Monipuolisuus:</strong> Paketti sopii monenlaisiin tapahtumiin, kuten häihin, pikkujouluihin, yritystilaisuuksiin ja live-musiikkiesityksiin.</li>
                                <li><strong>Helppo kuljetttaa:</strong> Kaiuttimet lumpsahtavat kiltisti kestäviin kantokahvallisiin suojalaukkuihinsa ja mikseri omaan olkalaukkuun johtoineen. Koko komeus mahtuu myös helposti henkilöautoon.</li>
                            </ul>

                            {/* Lopetusviesti */}
                            <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                Ota yhteyttä ja varaa tämä huippupaketti tapahtumaasi!
                            </p>

                            {/* Sosiaalisen median jakopainikkeet */}
                            <div className="flex flex-row py-5 gap-2" title='Jaa tämä sivu'>
                                <FacebookShareButton
                                    url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                    className="facebook-share-button flex flex-row items-center"
                                    aria-label="Jaa Facebookissa"
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>

                                <TwitterShareButton
                                    url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                    className="twitter-share-button flex flex-row items-center" 
                                    aria-label="Jaa Twitterissä"
                                >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>

                                <WhatsappShareButton
                                    url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                    className="whatsapp-share-button flex flex-row items-center" 
                                    aria-label="Jaa WhatsAppissa"
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>

                                <LinkedinShareButton
                                    url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                    className="linkedin-share-button flex flex-row items-center" 
                                    aria-label="Jaa LinkedInissä"
                                >
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>

                                <EmailShareButton
                                    url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                    className="email-share-button flex flex-row items-center" 
                                    aria-label="Jaa sähköpostilla"
                                >
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                            </div>

                            {/* Hinta ja TextToSpeech */}
                            <div className="flex flex-row items-center">
                                <h2 
                                    title="Hinta" 
                                    className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'
                                >
                                    Alk. 10€ / vrk per kpl
                                </h2>
                                <div className='speech-title ml-5 flex justify-center'>
                                    <TextToSpeech text={`Valitsemäsi määrä on ${quantity} kappaletta, joten kokonaishinta on ${calculateTotalPrice()} euroa per vuorokausi.`} />
                                </div>
                            </div>

                            {/* Määrän valinta ja saatavuus */}
                            <div className="mt-5">
                                <label htmlFor="quantity" className="block text-lg font-medium text-darkest dark:text-primary">
                                    Valitse määrä:
                                </label>
                                <input 
                                    type="number" 
                                    id="quantity" 
                                    name="quantity" 
                                    min="1" 
                                    max={available} 
                                    value={quantity} 
                                    onChange={(e) => {
                                        const val = parseInt(e.target.value, 10);
                                        if (isNaN(val)) {
                                            setQuantity(1);
                                        } else {
                                            setQuantity(Math.min(Math.max(1, val), available));
                                        }
                                    }}
                                    className="mt-1 block w-24 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                />
                                <p className="mt-1 font-medium text-darkest dark:text-primary">Saatavilla: {available} kpl</p>
                            </div>

                            {/* Lisää vuokrauskoriin-nappi */}
                            <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                                <button
                                    title="Lisää vuokrauskoriin"
                                    onClick={addToCart}
                                    className={`h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                        available <= 0 ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                    disabled={available <= 0}
                                >
                                    {available <= 0 ? 'Lisätty koriin' : 'Lisää vuokrauskoriin'}
                                </button>

                                <Link 
                                    to="/varauskalenteri" 
                                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                    className="w-full sm:w-60"
                                >
                                    <button
                                        title="Siirry varaamaan"
                                        className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                        style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                    >
                                        Siirry varauskalenteriin
                                    </button>
                                </Link>
                            </div>

                            {/* Tuotteen logo */}
                            <div className='flex flex-wrap place-items-start pt-5'>
                                <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                    <a href="https://www.chauvetprofessional.com/" aria-label="Vieraile Chauvet:in sivuilla." title="Vieraile Chauvet:in sivuilla.">
                                        <img 
                                            src={Chauvet}  
                                            alt="Chauvet logo" 
                                            loading="lazy" 
                                            className='w-full h-full object-contain rounded-xl' 
                                        />
                                     </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LEDLights;
