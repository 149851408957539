// src/components/ADJStingerSpot30HelmetComponent.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

function ADJStingerSpot30HelmetComponent() {
  return (
    <Helmet>
      {/* Page title */}
      <title>Spiderlegs Productions | ADJ Stinger Spot 30</title>
      
      {/* Meta description */}
      <meta
        name="description"
        content="ADJ Stinger Spot 30. Tehokas ja monipuolinen valonheitin, joka sopii täydellisesti niin live-esityksiin kuin tapahtumien valaistukseen. Korkean suorituskyvyn LED-valot tarjoavat kirkkaat ja tarkat valotehosteet."
      />
      
      {/* Meta keywords */}
      <meta
        name="keywords"
        content="ADJ, Stinger Spot 30, valonheitin, LED-valot, live-esitys, tapahtumat, valaistus, monipuolinen, tehokas"
      />
      
      {/* Meta author */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Canonical link */}
      <link rel="canonical" href="https://spiderlegsproductions.com/adj-stinger-spot-30" />
      
      {/* Open Graph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs Productions | ADJ Stinger Spot 30" />
      <meta
        property="og:description"
        content="ADJ Stinger Spot 30. Tehokas ja monipuolinen valonheitin, joka sopii täydellisesti niin live-esityksiin kuin tapahtumien valaistukseen. Korkean suorituskyvyn LED-valot tarjoavat kirkkaat ja tarkat valotehosteet."
      />
      <meta
        property="og:image"
        content="https://spiderlegsproductions.com/assets/ADJ_Stinger_Spot_30.webp"
      />
      <meta property="og:url" content="https://spiderlegsproductions.com/adj-stinger-spot-30" />
      
      {/* Twitter Card tags */}
      <meta name="twitter:title" content="Spiderlegs Productions | ADJ Stinger Spot 30" />
      <meta
        name="twitter:description"
        content="ADJ Stinger Spot 30. Tehokas ja monipuolinen valonheitin, joka sopii täydellisesti niin live-esityksiin kuin tapahtumien valaistukseen. Korkean suorituskyvyn LED-valot tarjoavat kirkkaat ja tarkat valotehosteet."
      />
      <meta
        name="twitter:image"
        content="https://spiderlegsproductions.com/assets/ADJ_Stinger_Spot_30.webp"
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default ADJStingerSpot30HelmetComponent;
