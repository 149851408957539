// src/components/Testimonial/FeedbackCard.jsx

import PropTypes from 'prop-types';

const FeedbackCard = ({ quote, author, position, avatar }) => {
  return (
    <div className="bg-white bg-opacity-75 dark:bg-opacity-75 p-6 rounded-lg shadow-md max-w-md mx-auto transition-transform transform hover:scale-105">
      <p className="italic text-darkest mb-4 relative pl-6 text-lg sm:text-xl">
        “{quote}”
        <span className="absolute left-0 top-0 text-4xl text-darkest">“</span>
      </p>
      <div className="flex items-center">
        <img
          src={avatar}
          alt={author}
          className="w-16 h-16 rounded-full object-cover mr-4 border-2 border-primary"
        />
        <div>
          <h4 className="text-lg font-customFont1 text-darkest font-semibold">{author}</h4>
          <p className="text-darkest">{position}</p>
        </div>
      </div>
    </div>
  );
};

// Propsien validointi
FeedbackCard.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};

export default FeedbackCard;
