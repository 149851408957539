import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images 
import ADJLogo from '../../assets/ADJ_logo.webp';
import img1 from '../../assets/ADJ_VF_Volcano1.webp';
import img2 from '../../assets/ADJ_VF_Volcano_Back.webp';
import img3 from '../../assets/ADJ_VF_Volcano2.webp';

// Import ThemeContext
import ThemeContext from '../../context/ThemeContext';

// Helper functions for cart management
const getCart = () => {
    return JSON.parse(localStorage.getItem("rentalCart")) || [];
};

const isProductInCart = (productId) => {
    const cart = getCart();
    return cart.some((item) => item.id === productId);
};

const getProductQuantityInCart = (productId) => {
    const cart = getCart();
    return cart
        .filter((item) => item.id === productId)
        .reduce((sum, item) => sum + item.quantity, 0);
};

const ADJVolcano = () => {
    const { theme } = useContext(ThemeContext); // Käytetään ThemeContext
    const [activeImg, setActiveImage] = useState(img1);
    const [available, setAvailable] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const productId = "8"; // ID Savukoneelle

     // Tekstit
     const text =
     "Savukone ADJ VF Volcano. Täydellinen savukone tanssilattialle tai lavalle. Kompakti, pystysuoraan ampuva savukone, joka tuo uuden ulottuvuuden savutehosteisiin. Se on varustettu kuusi kertaa kolmen watin RGB-LEDillä, jotka sekoittavat värejä savuun ja luovat upeita, värikkäitä tehosteita.";

    const text1 =
        "Uusi lämmitysteknologia. ADJ:n lämmitinteknologian uusien edistysaskeleiden ansiosta VF Volcano tuottaa kuivan, tasaisen savupatsaan yli neljän pilkku viiden metrin korkeuteen. Erityinen lämmityselementtirakenne ja öljytyyppinen voimansiirto takaavat, että laite ei tukkeudu."; 

    const text2 =
        "Tehokas ja luotettava: Savukone on varustettu tehokkaalla italialaisella pumppujärjestelmällä, joka takaa optimaalisen tehon. ADJ:n Electronic Thermo Sensing (ETS) -tekniikka pitää lämpötilan optimaalisena ja takaa lyhyen lämpenemisajan savujen välillä.";

    const text3 =
        "Hinta. Alkaen neljäkymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

    const text4 =
        "Tekniset tiedot. Mitat: 304 x 230,5 x 97,5 mm, Paino: 5 kg, Kaukosäädin, Kantolaukku, Vuokraa tämä monipuolinen valo tapahtumaasi piristämään tunnelmaa!";

    // Hae tuotteen saatavuus backendistä
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
                const data = await response.json();

                if (!data.categories) {
                    throw new Error("Categories field is missing in API response");
                }

                // Yhdistä kaikki kategoriat yhteen taulukkoon
                const inventory = Object.values(data.categories).flat();

                // Etsi tuote ID:n perusteella
                const currentProduct = inventory.find(item => item.id === productId);

                if (currentProduct && Object.prototype.hasOwnProperty.call(currentProduct, 'available')) {
                    const availableNumber = typeof currentProduct.available === 'number' 
                        ? currentProduct.available 
                        : parseInt(currentProduct.available, 10);
                    const currentQuantityInCart = getProductQuantityInCart(productId);
                    const finalAvailable = isNaN(availableNumber) ? 0 : availableNumber - currentQuantityInCart;
                    setAvailable(Math.max(finalAvailable, 0));
                    if (finalAvailable <= 1) {
                        setQuantity(1); // Aseta määrä automaattisesti 1:een
                    }
                } else {
                    console.error(`Product not found with ID ${productId} or 'available' field is missing!`);
                    // toast.error("Tuotteen saatavuustiedot puuttuvat. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            } catch (error) {
                console.error("Error fetching API data:", error);
                // toast.error("Virhe saatavuuden hakemisessa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    // AddToCart function
       const addToCart = async () => {
           try {
               // Hae varastotilanne uudelleen varmistaaksesi tuoreimman tiedon
               const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
               if (!response.ok) {
                   throw new Error(`HTTP error! Status: ${response.status}`);
               }
               const data = await response.json();
               const inventory = Object.values(data.categories).flat();
               const currentProduct = inventory.find(item => item.id === productId);
               const productStock = Number(currentProduct?.available) || 0;
   
               // Hae ostoskorin nykyinen määrä
               const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
               const existingProduct = cart.find(item => item.id === productId);
               const currentQuantityInCart = existingProduct ? existingProduct.quantity : 0;
   
               // Laske jäljellä oleva varasto
               const availableTotal = productStock - currentQuantityInCart;
   
               // Tarkista, ettei lisättävä määrä ylitä varastoa
               if (quantity > availableTotal) {
                //    toast.error(`Valittu määrä ylittää saatavilla olevan varaston! (${availableTotal} kpl jäljellä)`, { position: "top-right" });
                   return;
               }
   
               // Lisää tai päivitä tuote ostoskoriin
               if (existingProduct) {
                   existingProduct.quantity += quantity;
                   existingProduct.totalPrice = existingProduct.quantity * existingProduct.price;
               } else {
                   cart.push({
                        id: productId,
                        name: "Savukone ADJ VF Volcano",
                        price: 40, // Vuorokausihinta (€)
                       quantity: quantity,
                    totalPrice: quantity * 50, // Kokonaiskustannukset
                });
            }

             // Varmista, ettei ostoskoriin lisätä yli varaston
            if (existingProduct && existingProduct.quantity > productStock) {
                existingProduct.quantity = productStock;
                existingProduct.totalPrice = existingProduct.quantity * existingProduct.price;
                // toast.error(`Lisättävä määrä ylittää saatavilla olevan varaston! (${availableTotal} kpl jäljellä)`, { position: "top-right" });
                return;
            }

            localStorage.setItem("rentalCart", JSON.stringify(cart));

            window.dispatchEvent(new Event("cartUpdated"));

            // Päivitä saatavuus
            setAvailable(Math.max(available - quantity, 0));

            // // Näytä onnistumisilmoitus
            // toast.success(`${quantity} x Savukone ADJ VF Volcano lisätty vuokrauskoriin!`, {
            //     position: "bottom-left",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });

        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            // toast.error("Tuotteen lisääminen vuokrauskoriin epäonnistui. Yritä myöhemmin.", { position: "top-right" });
        }
    };

    return (
        <>
            {/* <ToastContainer
                toastClassName={() =>
                    "relative flex items-center justify-between p-6 mb-4 w-full max-w-full dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest text-xl font-semibold rounded-lg shadow-lg border border-gray-300"
                }
            /> */}

            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className={`h-12 w-32 ${
                    theme === 'dark' ? 'dark:bg-violet dark:text-primary dark:hover:border-2 dark:border-primary' : 'bg-primary text-darkest hover:border-2 border-darkest'
                } font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none`}
                aria-label="Takaisin"
            >
                Takaisin
            </button>
               
            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>

                <div className='flex flex-col gap-5'>

                    <img 
                        src={activeImg} 
                        alt='Savukone ADJ VF Volcano' 
                        title="Savukone ADJ VF Volcano" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'
                    />

                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>

                        {/* Klikattavat kuvat korvattu button-elementeillä */}
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className='w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none'
                            aria-label="Näytä Savukone ADJ VF Volcano Etupuoli"
                        >
                            <img 
                                src={img1} 
                                alt="Savukone ADJ VF Volcano Etupuoli" 
                                title="Savukone ADJ VF Volcano Etupuoli" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className='w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none'
                            aria-label="Näytä Savukone ADJ VF Volcano Paneeli"
                        >
                            <img 
                                src={img2} 
                                alt="Savukone ADJ VF Volcano Paneeli" 
                                title="Savukone ADJ VF Volcano Paneeli" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(img3)} 
                            className='w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none'
                            aria-label="Näytä Savukone ADJ VF Volcano Kaukosäädin"
                        >
                            <img 
                                src={img3} 
                                alt="Savukone ADJ VF Volcano Kaukosäädin" 
                                title="Savukone ADJ VF Volcano + Kaukosäädin"  
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>

                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>Videot</h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/jYk12Dg-BzE?si=sVFfpZ9g8I4-FoFu"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Savukone ADJ VF Volcano Product Video"
                            aria-label="Savukone ADJ VF Volcano Product Video"
                        ></iframe>
                    </div>
                </div>

                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>

                    <div>

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Otsikko */}
                            <h1 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none'>Savukone ADJ VF Volcano</h1>
                            <div className='speech-title ml-5 flex justify-center'>

                                <TextToSpeech text={text} />

                            </div>    
                            
                        </div>
                            
                        <br />

                        {/* Teksti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            <strong>Täydellinen savukone tanssilattialle tai lavalle.</strong> Kompakti, pystysuoraan ampuva savukone, joka tuo uuden ulottuvuuden savutehosteisiin. Se on varustettu 6 x 3 watin RGB-LEDillä, jotka sekoittavat värejä savuun ja luovat upeita, värikkäitä tehosteita.
                        </p>
                        
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                <strong>Uusi lämmitysteknologia:</strong> ADJ:n lämmitinteknologian uusien edistysaskeleiden ansiosta VF Volcano tuottaa kuivan, tasaisen savupatsaan yli 4,5 metrin korkeuteen. Erityinen lämmityselementtirakenne ja öljytyyppinen voimansiirto takaavat, että laite ei tukkeudu.
                            </p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text1} />
                                
                            </div>

                        </div>
                            
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                <strong>Tehokas ja luotettava:</strong> VF Volcano on varustettu tehokkaalla italialaisella pumppujärjestelmällä, joka takaa optimaalisen tehon. ADJ:n Electronic Thermo Sensing (ETS) -tekniikka pitää lämpötilan optimaalisena ja takaa lyhyen lämpenemisajan savujen välillä.
                            </p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text2} />
                                
                            </div>

                        </div>
                            
                        <br />

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>Tekniset tiedot:</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text4} />

                            </div>

                        </div>

                        <ul className="ominaisuudet list-disc font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl 3xl:text-3xl font-normal p-4">
                            <li>Mitat: 304 x 230,5 x 97,5 mm</li>
                            <li>Paino: 5 kg</li>
                            <li>Kaukosäädin</li>
                            <li>Kantolaukku</li>
                        </ul>
                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">Vuokraa tämä monipuolinen valo tapahtumaasi piristämään tunnelmaa!</p>

                        {/* Jaa-painikkeet */}
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">

                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="facebook-share-button flex flex-row items-center"
                                aria-label="Jaa Facebookissa"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="twitter-share-button flex flex-row items-center"
                                aria-label="Jaa Twitterissä"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="whatsapp-share-button flex flex-row items-center"
                                aria-label="Jaa WhatsAppissa"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="linkedin-share-button flex flex-row items-center"
                                aria-label="Jaa LinkedInissä"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="email-share-button flex flex-row items-center"
                                aria-label="Jaa sähköpostilla"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>

                        </div>

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 title="Hinta" className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>Alk. 40€ / vrk</h2>
                            <div className='speech-title ml-5 flex justify-center'>

                                <TextToSpeech text={text3} />
                                
                            </div>

                        </div>

                       {/* Lisää vuokrauskoriin-nappi */}
                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                            <div className="flex flex-col items-center gap-2 w-full sm:w-auto">
                                <button
                                    title="Lisää vuokrauskoriin"
                                    onClick={addToCart}
                                    className={`h-12 w-full sm:w-60 ${
                                        theme === 'dark' ? 'dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest' : 'bg-darkest text-primary hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover'
                                    } font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                        isProductInCart(productId) || available <= 0 ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                    disabled={isProductInCart(productId) || available <= 0}
                                >
                                    {isProductInCart(productId) ? 'Lisätty koriin' : 'Lisää vuokrauskoriin'}
                                </button>
                            </div>

                            <Link 
                                to="/varauskalenteri" 
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                className="w-full sm:w-auto"
                            >
                                <button
                                    title="Siirry varaamaan"
                                    className="h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Siirry varauskalenteriin
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>

                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">

                                {/* Tuotteen logo */}

                                <a href="https://www.adj.eu/" aria-label="Vieraile ADJ:n sivuilla." title="Vieraile ADJ:n sivuilla">
                                    <img 
                                        src={ADJLogo} 
                                        alt="ADJ logo" 
                                        loading="lazy" 
                                        title="Vieraile ADJ:n sivuilla."
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default ADJVolcano;
