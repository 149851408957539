// src/components/Rental.jsx
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Komponenttien tuonti
import SoundRental from '../../components/SoundRental/SoundRental.jsx';
import Footer from '../../components/Footer/Footer';
import LightRental from '../../components/LightRental/LightRental.jsx';
import MultiLayerParallax from '../../components/MultiLayerParallax/MultiLayerParallax.tsx';

import Partybox from '../../Pages/PartyBox300/Partybox300';
import PreSonus2000W from "../../Pages/PreSonus2000W/PreSonus2000W";
import Mackie300 from '../../components/Mackie300W/Mackie300';
import JBL625W from '../../components/JBL625W/JBL625W'; // Oikea komponentti
import DB600W from '../../components/DB600W/DB600W';
import SoundcraftFX16 from '../../components/SoundcraftFX16/SoundcraftFX16';

import ADJStinger2 from '../../components/ADJ-Stinger2/ADJ-Stinger2';
import ADJVolcano from '../../components/ADJ-Volcano/ADJ-Volcano'; // Oikea importti
import LEDLights from '../../components/LED-Lights/LED-Lights';
import RentalHelmetComponent from './RentalHelmetComponent';
import ADJmyDMX from '../ADJ-GO/ADJmyDMXGO';
import OtherRentals from '../../components/OtherRentals/OtherRentals';
import FujifilmInstaxMini12 from '../../components/FujifilmInstaxMini12/FujifilmInstaxMini12.jsx';
import ADJStingerSpot30 from '../../components/ADJStingerSpot30/ADJStingerSpot30.jsx';

const Rental = () => {
  const { laite } = useParams();
  const [setPageText] = useState({ title: '', body: '' });

  useEffect(() => {
    const fetchPageText = async () => {
      try {
        // Oletettavasti hakee otsikot ja tekstit palvelimelta annetun parametrin perusteella
        const response = await fetch(`url/jostain/api:sta/${laite}`);
        const data = await response.json();
        setPageText(data);
      } catch (error) {
        console.error('Error fetching page text:', error);
      }
    };

    fetchPageText();
  }, [laite, setPageText]);

  const getPath = () => {
    switch(laite) {
      case "ledvalot":
        return (
          <>
            <LEDLights />
          </>
        );
      case "volcano":
        return (
          <>
            <ADJVolcano />
          </>
        );
      case "stinger":
        return (
          <>
            <ADJStinger2 />
          </>
        );
      case "stingerspot":
        return (
          <>
            <ADJStingerSpot30 />
          </>
        );

      case "polaroid":
        return (
          <>
            <FujifilmInstaxMini12 />
          </>
        );
      case "dmxgo":
        return (
          <>
            <ADJmyDMX />
          </>
        );
      case "soundcraft":
        return (
          <>
            <SoundcraftFX16 />
          </>
        );
      case "Db600w":
        return (
          <>
            <DB600W />
          </>
        );
      case "625w":
        return (
          <>
            <JBL625W />
          </>
        );
      case "300w":
        return (
          <>
            <Mackie300 />
          </>
        );
      case "2000w":
        return (
          <>
            <PreSonus2000W />
          </>
        );
      case "jblpartybox":
        return (
          <>
            <Partybox />
          </>
        );
      default:
        return (
          <>
            <MultiLayerParallax />
            <SoundRental />
            <LightRental />
            <OtherRentals />
          </>
        );
    }
  };

  return (
    <>
      {getPath()}
      <RentalHelmetComponent />
      <Footer />
    </>
  );
};

export default Rental;
