// src/components/FujifilmInstaxMini12/FujifilmInstaxMini12.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images 
import FujifilmLogo from '../../assets/Fujufilm_logo.webp'; // Varmista polku ja tiedostonimi
import InstaxMini12 from '../../assets/mini12.webp';
import InstaxMini122 from '../../assets/mini122.webp';

// Helper functions for cart management
const getCart = () => {
    return JSON.parse(localStorage.getItem("rentalCart")) || [];
};

// Import ThemeContext
import ThemeContext from '../../context/ThemeContext';

// Define packageIDs outside the component to prevent re-creation on each render
const packageIDs = [11, 12, 13]; // Peruspaketti, Keskipaketti, Premium-paketti

const isProductInCart = (productId) => {
    const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
    return cart.some((item) => item.id === productId);
};


const FujifilmInstaxMini12 = () => {
    const { theme } = useContext(ThemeContext); // Käytetään ThemeContext
    const [activeImg, setActiveImage] = useState(InstaxMini12);
    const [availableStock, setAvailableStock] = useState({});
    const [selectedPackage, setSelectedPackage] = useState("");
    const [packages, setPackages] = useState([]); // Define 'packages' as a state variable

    // Tekstit
    const text = "Fujifilm Instax Mini-12, kompaktikamera";
    const text1 = "Tämä polaroid-kamera on täydellinen valinta ikuistamaan unohtumattomia hetkiä! Helppokäyttöinen ja kompakti, Instax Mini-12 on täydellinen kumppani juhliin, häihin ja muihin tapahtumiin. Vuokraa tämä kamera ja luo hetkessä fyysisiä muistoja tapahtumistasi.";
    const text2 = "Vuokrapaketit alkavat 80 eurosta vuorokaudessa. Voit valita sopivan paketin tilaisuutesi tarpeisiin. Hinta sisältää kameran sekä valokuvapaperin, ja asiakas voi pyynnöstä valita toisenlaista valokuvapaperia, joka otetaan huomioon lopullisessa hinnassa.";
    const textPeruspaketti = "Peruspaketti sisältää kameran sekä 20 kuvan valokuvapaperin (kaksi kymmenen kuvan pakettia). Tämä paketti sopii erinomaisesti pieniin ja keskikokoisiin tilaisuuksiin, kuten syntymäpäiväjuhliin tai perhetapahtumiin.";
    const textKeskipaketti = "Keskipaketti sisältää kameran sekä 50 kuvan valokuvapaperin (viisi kymmenen kuvan pakettia). Tämä paketti soveltuu hyvin keskikokoisiin tapahtumiin, kuten perhejuhliin, yritystilaisuuksiin tai pienempiin häihin.";
    const textPremiumPaketti = "Premium-paketti sisältää kameran sekä 100 kuvan valokuvapaperin (10 x 10 kuvan pakettia). Tämä paketti on ihanteellinen suurille tapahtumille, kuten häille tai suurille yritystilaisuuksille, joissa tarvitaan paljon kuvia.";

    
    // Handle Image Click
    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=muut");
                const data = await response.json();
    
                if (!data.success || !data.categories || !data.categories.muut) {
                    throw new Error("Categories field is missing or invalid in API response");
                }
    
                const fetchedPackages = data.categories.muut.filter(item => packageIDs.includes(parseInt(item.id)));
    
                setPackages(fetchedPackages);
    
                const stock = {};
                fetchedPackages.forEach(pkg => {
                    stock[pkg.id] = pkg.available;
                });
    
                setAvailableStock(stock);
            } catch (error) {
                console.error("Error fetching availability:", error);
                // toast.error("Virhe saatavuuden hakemisessa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };
    
        fetchAvailability();
    }, []);

    // Kuuntele ostoskorin päivityksiä (jos tarvitaan)
    useEffect(() => {
        const handleCartUpdate = () => {

            const fetchAvailability = async () => {
                try {
                    const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=muut");
                    const data = await response.json();

                    if (!data.success || !data.categories || !data.categories.muut) {
                        throw new Error("Categories field is missing in API response");
                    }

                    const fetchedPackages = data.categories.muut.filter(item => packageIDs.includes(item.id));
                    setPackages(fetchedPackages);

                    const stock = {};
                    fetchedPackages.forEach(pkg => {
                        stock[pkg.id] = pkg.available;
                    });

                    setAvailableStock(stock);

                } catch (error) {
                    console.error("Error fetching API data on cart update:", error);
                    // toast.error("Virhe saatavuuden hakemisessa. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            };

            fetchAvailability();
        };

        window.addEventListener("cartUpdated", handleCartUpdate);

        return () => {
            window.removeEventListener("cartUpdated", handleCartUpdate);
        };
    }, []);

    const addToCart = async () => {
        if (!selectedPackage) {
            // toast.error("Valitse vuokrapaketti ennen lisäämistä!", { position: "top-right" });
            // return;
        }

        // Tarkista, onko valittu paketti olemassa ja saatavilla
        const selectedProduct = packages.find(pkg => String(pkg.id) === selectedPackage);

        if (!selectedProduct) {
            // toast.error("Virhe paketin valinnassa. Yritä uudelleen.", { position: "top-right" });
            return;
            
        }

        if (!selectedProduct) {
            // toast.error("Virhe paketin valinnassa. Yritä uudelleen.", { position: "top-right" });
            return;
        }

        // Hae ostoskori ja tarkista, onko siellä jo tuote kategoriasta "muut"
        const cart = getCart();
        const existingCameraPackage = cart.find(item => item.category === "muut");

        if (existingCameraPackage) {
            // toast.error("Voit lisätä vain yhden kameran tai paketin kerrallaan!", { position: "top-right" });
            return;
        }

        try {
            // Päivitä varasto backendissä
     
            const response = await fetch('https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=muut', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tuoteID: selectedProduct.id, action: 'add' }), // Lisää action-parametri
            });

            const result = await response.json();

            if (!result.success) {
                // toast.error(result.message || "Varastoa ei päivitetty", { position: "top-right" });
                return;
            }

            // Päivitä paikallinen varasto
            setAvailableStock(prevStock => ({
                ...prevStock,
                [selectedProduct.id]: prevStock[selectedProduct.id] - 1,
            }));

            // Lisää tuote ostoskoriin
            cart.push({
                id: selectedProduct.id,
                name: selectedProduct.name,
                price: selectedProduct.price,
                category: "muut",
                quantity: 1,
            });

            // Tallenna ostoskori
            localStorage.setItem('rentalCart', JSON.stringify(cart));
            window.dispatchEvent(new Event("cartUpdated"));

            // toast.success(`${selectedProduct.name} lisätty vuokrauskoriin!`, {
            //     position: "bottom-left",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });
        } catch (error) {
            console.error("Error updating stock:", error);
            // toast.error("Virhe varaston päivittämisessä. Yritä myöhemmin uudelleen.", { position: "top-right" });
        }
    };

    return (
        <>
            {/* <ToastContainer
                toastClassName={() =>
                    "relative flex items-center justify-between p-6 mb-4 w-full max-w-full dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest text-xl font-semibold rounded-lg shadow-lg border border-gray-300"
                }
            /> */}

            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className={`h-12 w-32 ${
                    theme === 'dark' ? 'dark:bg-violet dark:text-primary dark:hover:border-2 dark:border-primary' : 'bg-primary text-darkest hover:border-2 border-darkest'
                } font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none`}
                aria-label="Takaisin"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className={`flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5`}>
                <div className='flex flex-col gap-5'>

                    <img 
                        src={activeImg} 
                        alt='Fujifilm Instax Mini-12 kamera' 
                        title="Fujifilm Instax Mini-12 kamera" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
                    />
                    
                    {/* Thumbnail Images */}
                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        <button 
                            onClick={() => handleImageClick(InstaxMini12)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä Fujifilm Instax Mini-12 etunäkymä"
                        >
                            <img 
                                src={InstaxMini12} 
                                alt="Fujifilm Instax Mini-12 etunäkymä" 
                                title="Fujifilm Instax Mini-12 etunäkymä" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(InstaxMini122)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä Fujifilm Instax Mini-12 sivunäkymä"
                        >
                            <img 
                                src={InstaxMini122} 
                                alt="Fujifilm Instax Mini-12 sivunäkymä" 
                                title="Fujifilm Instax Mini-12 sivunäkymä" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                    </div>
                </div>

                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>
                        {/* Puhepainike */}
                        <div className="flex flex-row items-center">
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                Fujifilm Instax Mini-12 kompaktikamera
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />

                        {/* Kamera-esittely */}
                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                Tämä polaroid-kamera on täydellinen valinta ikuistamaan unohtumattomia hetkiä! Helppokäyttöinen ja kompakti, Instax Mini-12 on täydellinen kumppani juhliin, häihin ja muihin tapahtumiin. Vuokraa tämä kamera ja luo hetkessä fyysisiä muistoja tapahtumistasi.
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        <br />

                        {/* Vuokrapaketit */}
                        <h2 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
                            Vuokrapaketit:
                        </h2>

                        {/* Paketit listattuna */}
                        {[
                            { title: "Peruspaketti", text: textPeruspaketti, price: "80€", details: "Kamera + 20 kuvan valokuvapaperi (2 x 10 kuvan pakettia)." },
                            { title: "Keskipaketti", text: textKeskipaketti, price: "140€", details: "Kamera + 50 kuvan valokuvapaperi (5 x 10 kuvan pakettia)." },
                            { title: "Premium-paketti", text: textPremiumPaketti, price: "200€", details: "Kamera + 100 kuvan valokuvapaperi (10 x 10 kuvan pakettia)." }
                        ].map(({ title, text, price, details }) => (
                            <div className="flex flex-row items-center mb-4" key={title}>
                                <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                                    <li><strong>{title}:</strong> {details} Hinta: {price}.</li>
                                </ul>
                                <div className='speech-title ml-5 flex justify-center'>
                                    <TextToSpeech text={text} />
                                </div>
                            </div>
                        ))}

                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            Asiakkaan pyynnöstä voimme käyttää toisenlaista valokuvapaperia, ja tämä otetaan huomioon lopullisessa hinnassa.
                        </p>
                        <br />
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            Valitse sinulle sopiva paketti yhteydenottolomakkeen täytön yhteydessä. Tämä auttaa meitä tarjoamaan juuri sinulle sopivan ratkaisun.
                        </p>

                        {/* Hinta-puhepainike */}
                        <div className="flex flex-row items-center mt-5">
                            <h2 className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>
                                Alk. 80€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        {/* Sosiaalisen median jakopainikkeet */}
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">
                            {[
                                { Component: FacebookShareButton, icon: FacebookIcon, url: "https://spiderlegsproductions.com/fujifilm-instax-mini-12", label: "Facebook" },
                                { Component: TwitterShareButton, icon: TwitterIcon, url: "https://spiderlegsproductions.com/fujifilm-instax-mini-12", label: "Twitter" },
                                { Component: WhatsappShareButton, icon: WhatsappIcon, url: "https://spiderlegsproductions.com/fujifilm-instax-mini-12", label: "WhatsApp" },
                                { Component: LinkedinShareButton, icon: LinkedinIcon, url: "https://spiderlegsproductions.com/fujifilm-instax-mini-12", label: "LinkedIn" },
                                { Component: EmailShareButton, icon: EmailIcon, url: "https://spiderlegsproductions.com/fujifilm-instax-mini-12", label: "Sähköposti" }
                            ].map(({ Component, icon: Icon, url, label }) => (
                                <Component url={url} className="flex flex-row items-center" aria-label={`Jaa ${label}:ssa`} key={label}>
                                    <Icon size={32} round />
                                </Component>
                            ))}
                        </div>

                        {/* Vuokrapakettivalinta ja saatavuusnäyttö */}
                        <label className="text-gray-400 mt-5">
                            <h2 className="font-customFont2 text-3xl dark:text-primary text-darkest">Kameran vuokrapaketit</h2>
                            <select 
                                className="w-full p-2 dark:bg-white border-gray-300 border rounded-full text-darkest dark:text-darkest"
                                value={selectedPackage}
                                onChange={(e) => {
                                    setSelectedPackage(e.target.value);
                                }} 
                                required
                            >
                                <option value="" disabled hidden>Valitse kameran vuokrapaketti</option>
                                {packages.map(pkg => (
                                    <option key={pkg.id} value={pkg.id}>
                                        {`${pkg.name} - ${pkg.price}€ (Saatavilla: ${availableStock[pkg.id] || 0} kpl)`}
                                    </option>
                                ))}
                            </select>

                            {/* Saatavuusnäyttö */}
                            <p className="text-gray-600 dark:text-gray-300 mt-3">
                                {isProductInCart(selectedPackage)
                                    ? "Kamera on varattu sinulle, voit poistaa sen listalta vuokrauskalenterista."
                                    : selectedPackage
                                    ? (availableStock[selectedPackage] > 0 ? `${availableStock[selectedPackage]} kpl saatavilla` : 'Ei saatavilla')
                                    : 'Valitse paketti'}
                            </p>
                        </label>


                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                            <button
                                title="Lisää vuokrauskoriin"
                                onClick={addToCart}
                                className={`h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                    isProductInCart(selectedPackage) || availableStock[selectedPackage] <= 0 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                disabled={isProductInCart(selectedPackage) || availableStock[selectedPackage] <= 0}
                            >
                                {isProductInCart(selectedPackage) ? 'Lisätty koriin' : 'Lisää vuokrauskoriin'}
                            </button>

                            <Link 
                                to="/varauskalenteri" 
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                className="w-full sm:w-60"
                            >
                                <button
                                    title="Siirry varaamaan"
                                    className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Siirry varauskalenteriin
                                </button>
                            </Link>
                        </div>


                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                <a href="https://www.fujifilm.com/" aria-label="Vieraile Fujifilmin sivuilla" title="Vieraile Fujifilmin sivuilla">
                                    <img 
                                        src={FujifilmLogo} 
                                        alt="Fujifilm logo" 
                                        loading="lazy" 
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FujifilmInstaxMini12;
