// src/components/ADJmyDMX/ADJmyDMX.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images
import ADJ from '../../assets/ADJ_logo.webp';
import img1 from '../../assets/ADJMyDMXGO1.webp';
import img2 from '../../assets/ADJMyDMXGO2webp.webp';
import img3 from '../../assets/ADJMyDMXGO3webp.webp';
import img4 from '../../assets/ADJMyDMXGO4webp.webp';

// Import ThemeContext
import ThemeContext from '../../context/ThemeContext';

// Helper functions for cart management
const getCart = () => {
    return JSON.parse(localStorage.getItem("rentalCart")) || [];
};

const isProductInCart = (productId) => {
    const cart = getCart();
    return cart.some((item) => item.id === productId);
};

const getProductQuantityInCart = (productId) => {
    const cart = getCart();
    return cart
        .filter((item) => item.id === productId)
        .reduce((sum, item) => sum + item.quantity, 0);
};

const ADJmyDMX = () => {
    const { theme } = useContext(ThemeContext); // Käytetään ThemeContext
    const [activeImg, setActiveImage] = useState(img1);
    const [available, setAvailable] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const productId = "10"; // ID myDMX GO:lle

    // Tekstit
    const text = "ADJiin, my deeämäks gou, langaton deeämäks-ohjain";

    const text1 = "ADJiin my Deeämäks gou on vallankumouksellinen uusi valaistuksen ohjausjärjestelmä, joka on erittäin tehokas ja uskomattoman helppokäyttöinen. My DMX gou -sovellus ei vaadi ohjelmointia, mutta sitä voidaan käyttää luomaan upeita synkronoituja valoesityksiä missä tahansa valaisinyhdistelmässä. Muutamassa sekunnissa voidaan luoda helposti uskomattomia valaistusnäyttöjä, jotka vaativat tuntikausia ohjelmointia perinteisillä ohjausjärjestelmillä. Yli viidentoistatuhannen profiilin kattavan valaisinkirjaston ansiosta my DMX gou:ta voidaan käyttää kaikentyyppisten DMX-valaistuksen ohjaamiseen mistä tahansa valmistajasta. Se on ihanteellinen liikkuvien viihdyttäjien käyttöön sekä pieniin yökerhoihin, baareihin ja vapaa-ajan kohteisiin, joissa tarvitaan yksinkertainen ja helppokäyttöinen valaistuksen ohjausjärjestelmä.";

    const text2 = "Hinta. Alkaen viisikymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

   // Hae tuotteen saatavuus backendistä
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
                const data = await response.json();

                if (!data.categories) {
                    throw new Error("Categories field is missing in API response");
                }

                // Yhdistä kaikki kategoriat yhteen taulukkoon
                const inventory = Object.values(data.categories).flat();

                // Etsi tuote ID:n perusteella
                const currentProduct = inventory.find(item => item.id === productId);

                if (currentProduct && Object.prototype.hasOwnProperty.call(currentProduct, 'available')) {
                    const availableNumber = typeof currentProduct.available === 'number' 
                        ? currentProduct.available 
                        : parseInt(currentProduct.available, 10);
                    const currentQuantityInCart = getProductQuantityInCart(productId);
                    const finalAvailable = isNaN(availableNumber) ? 0 : availableNumber - currentQuantityInCart;
                    setAvailable(Math.max(finalAvailable, 0));
                    if (finalAvailable <= 1) {
                        setQuantity(1); // Aseta määrä automaattisesti 1:een
                    }
                } else {
                    console.error(`Product not found with ID ${productId} or 'available' field is missing!`);
                    // toast.error("Tuotteen saatavuustiedot puuttuvat. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            } catch (error) {
                console.error("Error fetching API data:", error);
                // toast.error("Virhe saatavuuden hakemisessa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

   // Listen for cart updates
    useEffect(() => {
        const handleCartUpdate = () => {
            const fetchAvailability = async () => {
                try {
                    const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
                    const data = await response.json();

                    if (!data.categories) {
                        throw new Error("Categories field is missing in API response");
                    }

                    const inventory = Object.values(data.categories).flat();
                    const currentProduct = inventory.find(item => item.id === productId);

                    if (currentProduct) {
                        const productStock = Number(currentProduct.available);
                        const currentQuantityInCart = getProductQuantityInCart(productId);
                        const finalAvailable = productStock - currentQuantityInCart;
                        setAvailable(Math.max(finalAvailable, 0));
                        // Adjust quantity if necessary
                        if (quantity > finalAvailable) {
                            setQuantity(Math.min(Math.max(1, finalAvailable), finalAvailable));
                        }
                    } else {
                        setAvailable(0);
                    }
                } catch (error) {
                    console.error("Error updating availability:", error);
                }
            };

            fetchAvailability();
        };

        window.addEventListener("cartUpdated", handleCartUpdate);
        return () => {
            window.removeEventListener("cartUpdated", handleCartUpdate);
        };
    }, [productId, quantity]);

    // AddToCart function
    const addToCart = async () => {
        try {
            // Hae varastotilanne uudelleen varmistaaksesi tuoreimman tiedon
            const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=esitystekniikka");
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const inventory = Object.values(data.categories).flat();
            const currentProduct = inventory.find(item => item.id === productId);
            const productStock = Number(currentProduct?.available) || 0;

            // Hae ostoskorin nykyinen määrä
            const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
            const existingProduct = cart.find(item => item.id === productId);
            const currentQuantityInCart = existingProduct ? existingProduct.quantity : 0;

            // Laske jäljellä oleva varasto
            const availableTotal = productStock - currentQuantityInCart;

            // Tarkista, ettei lisättävä määrä ylitä varastoa
            if (quantity > availableTotal) {
                // toast.error(`Valittu määrä ylittää saatavilla olevan varaston! (${availableTotal} kpl jäljellä)`, { position: "top-right" });
                return;
            }

            // Lisää tai päivitä tuote ostoskoriin
            if (existingProduct) {
                existingProduct.quantity += quantity;
                existingProduct.totalPrice = existingProduct.quantity * existingProduct.price;
            } else {
                cart.push({
                    id: productId,
                    name: "ADJ myDMX GO langaton DMX-ohjain",
                    price: 50, // Vuorokausihinta (€ per päivä)
                    quantity: quantity,
                    totalPrice: quantity * 50, // Kokonaiskustannukset
                });
            }

            // Varmista, ettei ostoskoriin lisätä yli varaston
            if (existingProduct && existingProduct.quantity > productStock) {
                existingProduct.quantity = productStock;
                existingProduct.totalPrice = existingProduct.quantity * existingProduct.price;
                // toast.error(`Lisättävä määrä ylittää saatavilla olevan varaston! (${availableTotal} kpl jäljellä)`, { position: "top-right" });
                return;
            }

            localStorage.setItem("rentalCart", JSON.stringify(cart));

            window.dispatchEvent(new Event("cartUpdated"));

            // Päivitä saatavuus
            setAvailable(Math.max(available - quantity, 0));

            // Näytetään toast-ilmoitus onnistuneesta lisäyksestä
            // toast.success(`${quantity} x ADJ myDMX GO lisätty vuokrauskoriin!`, {
            //     position: "bottom-left",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "colored",
            // });

        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            // toast.error("Tuotteen lisääminen vuokrauskoriin epäonnistui. Yritä myöhemmin.", { position: "top-right" });
        }
    };

    return (
        <>
             {/* <ToastContainer
                toastClassName={() =>
                    "relative flex items-center justify-between p-6 mb-4 w-full max-w-full dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest text-xl font-semibold rounded-lg shadow-lg border border-gray-300"
                }
            /> */}

            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className={`h-12 w-32 ${
                    theme === 'dark' ? 'dark:bg-violet dark:text-primary dark:hover:border-2 dark:border-primary' : 'bg-primary text-darkest hover:border-2 border-darkest'
                } font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none`}
                aria-label="Takaisin"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    <img 
                        src={activeImg} 
                        alt='ADJ myDMX GO langaton DMX-ohjain' 
                        title="ADJ myDMX GO langaton DMX-ohjain" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
                    />
                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* Kuvien ympäröiminen button-elementeillä saavutettavuuden parantamiseksi */}
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Etu"
                        >
                            <img 
                                src={img1} 
                                alt="ADJ myDMX GO Etukuva" 
                                title="ADJ myDMX GO Etukuva" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Sivukuva"
                        >
                            <img 
                                src={img2} 
                                alt="ADJ myDMX GO Sivukuva" 
                                title="ADJ myDMX GO Sivukuva" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(img3)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Paneelikuva"
                        >
                            <img 
                                src={img3} 
                                alt="ADJ myDMX GO Paneelikuva" 
                                title="ADJ myDMX GO Paneelikuva"  
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(img4)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Kaukosäädin"
                        >
                            <img 
                                src={img4} 
                                alt="ADJ myDMX GO Kaukosäädin" 
                                title="ADJ myDMX GO Kaukosäädin"  
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
                        Videot
                    </h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/7gXPzcXNHWE?si=F4oRxOoDE6rjfJlS"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="ADJ myDMX GO Tuotevideo"
                        ></iframe>
                    </div>
                </div>

                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>
                        {/* Otsikko ja Speech-to-Text */}
                        <div className="flex flex-row items-center">
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                ADJ myDMX GO langaton DMX-ohjain
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />

                        {/* Kuvaus */}
                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                <strong>ADJ myDMX GO</strong> on vallankumouksellinen uusi valaistuksen ohjausjärjestelmä, joka on erittäin tehokas ja uskomattoman helppokäyttöinen.
                                <br /><br />Mydmx GO -sovellus ei vaadi ohjelmointia, mutta sitä voidaan käyttää luomaan upeita synkronoituja valoesityksiä missä tahansa valaisinyhdistelmässä. 
                                <br /><br />Muutamassa sekunnissa voidaan luoda helposti uskomattomia valaistusnäyttöjä, jotka vaativat tuntikausia ohjelmointia perinteisillä ohjausjärjestelmillä.
                                <br /><br />Yli 15 000 profiilin kattavan valaisinkirjaston ansiosta myDMX GO:ta voidaan käyttää kaikentyyppisten DMX-valaistuksen ohjaamiseen mistä tahansa valmistajasta. 
                                <br /><br />Se on ihanteellinen liikkuvien viihdyttäjien käyttöön sekä pieniin yökerhoihin, baareihin ja vapaa-ajan kohteisiin, joissa tarvitaan yksinkertainen ja helppokäyttöinen valaistuksen ohjausjärjestelmä.
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        <br />

                        {/* Jakopainikkeet */}
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">
                            <FacebookShareButton 
                                url={"https://spiderlegsproductions.com/adj-mydmx-go"} 
                                className="facebook-share-button flex flex-row items-center" 
                                aria-label="Jaa Facebookissa"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton 
                                url={"https://spiderlegsproductions.com/adj-mydmx-go"} 
                                className="twitter-share-button flex flex-row items-center" 
                                aria-label="Jaa Twitterissä"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton 
                                url={"https://spiderlegsproductions.com/adj-mydmx-go"} 
                                className="whatsapp-share-button flex flex-row items-center" 
                                aria-label="Jaa WhatsAppissa"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <LinkedinShareButton 
                                url={"https://spiderlegsproductions.com/adj-mydmx-go"} 
                                className="linkedin-share-button flex flex-row items-center" 
                                aria-label="Jaa LinkedInissä"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <EmailShareButton 
                                url={"https://spiderlegsproductions.com/adj-mydmx-go"} 
                                className="email-share-button flex flex-row items-center" 
                                aria-label="Jaa sähköpostilla"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>

                        {/* Hinta */}
                        <div className="flex flex-row items-center">
                            <h2 title="Hinta" className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>
                                Alk. 50€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        {/* Lisää vuokrauskoriin-nappi */}
                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                        <button
                                title="Lisää vuokrauskoriin"
                                onClick={addToCart}
                                className={`h-12 w-full sm:w-60 ${
                                    theme === 'dark' ? 'dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest' : 'bg-darkest text-primary hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover'
                                } font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                    isProductInCart(productId) || available <= 0 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                disabled={isProductInCart(productId) || available <= 0}
                            >
                                {isProductInCart(productId) ? 'Lisätty koriin' : 'Lisää vuokrauskoriin'}
                            </button>

                            <Link 
                                to="/varauskalenteri" 
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                className="w-full sm:w-60"
                            >
                                <button
                                    title="Siirry varaamaan"
                                    className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Siirry varauskalenteriin
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                <a href="https://www.adj.eu/" aria-label="Siirry ADJ:n verkkosivuille" title="Vieraile ADJ:n sivuilla.">
                                    <img 
                                        src={ADJ} 
                                        alt="ADJ logo" 
                                        loading="lazy" 
                                        title="Vieraile ADJ:n sivuilla."
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ADJmyDMX;
