// src/App.jsx
import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from "aos";
import "aos/dist/aos.css";
import './styles.css';
import CookieConsent from 'react-cookie-consent';
import { FaArrowUp } from "react-icons/fa";

// Sivun komponentit
import Navbar from "./components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Rental from "./Pages/Rental/Rental";
import AboutUs from "./Pages/AboutUs/AboutUs";
import UKK from "./Pages/UKK/UKK";
import Booking from "./Pages/Contactpage/Contactpage";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Partybox from "./Pages/PartyBox300/Partybox300";
import Mackie300W from "./Pages/Mackie300W/Mackie300W";
import JBL625W from "./Pages/JBL635W/JBL625W";
import PreSonus2000W from "./Pages/PreSonus2000W/PreSonus2000W";
import DB600W from "./Pages/DB600W/DB600";
import SoundcraftFX16 from "./Pages/SoundcraftFX16/SoundcraftFX16";
import NotFound from "./Pages/NotFound/NotFound";
import ADJStinger2 from "./Pages/ADJ-Stinger2/ADJ-Stinger2";
import ADJVolcano from "./Pages/ADJ-Volcano/ADJ-Volcano";
import LEDLights from "./Pages/LED-Lights/LED-lights";
import ADJmyDMX from "./Pages/ADJ-GO/ADJmyDMXGO";
import FujifilmInstaxMini12Page from "./Pages/FujifilmInstaxMini12/FujifilmInstaxMini12";
import ADJStingerSpot30 from './Pages/ADJStingerSpot30/ADJStingerSpot30'; // Oikea tuonti


function App() {
  const [theme, setTheme] = useState(() => localStorage.getItem("theme") || "dark");
  const [showButton, setShowButton] = useState(false);
  // const [toastPosition, setToastPosition] = useState("bottom-left");

  // Teeman vaihto
  useEffect(() => {
    const element = document.documentElement;
    if (theme === "dark") {
      element.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  // Scroll-nappi ja progress bar
  const onScroll = () => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.body.scrollHeight - window.innerHeight;
    const progress = (scrollTop / scrollHeight) * 100;

    const progressBar = document.getElementById("bar");
    if (progressBar) {
      progressBar.style.width = `${progress}%`;
    }

    setShowButton(scrollTop > 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  // AOS-initialisointi
  useEffect(() => {
    AOS.init({ offset: 100, duration: 400, easing: "ease-in-sine", delay: 0 });
  }, []);

  // // Toastin sijainnin päivitys responsiivisesti
  // useEffect(() => {
  //   const handleResize = () => {
  //     setToastPosition(window.innerWidth <= 768 ? "top-right" : "bottom-left");
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <>
      <Navbar theme={theme} setTheme={setTheme} />

      {/* ToastContainer */}
      {/* <ToastContainer
        position={toastPosition}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme === 'dark' ? 'dark' : 'light'}
        bodyClassName="flex items-center justify-center"
        style={{ zIndex: 99999 }} // Varmista, että toastit näkyvät muiden elementtien päällä
      /> */}

      {/* Yksinkertainen Testi Toastille */}
      {/* <div className="fixed bottom-20 right-5">
        <button
          onClick={() => toast.success("Testi Toast!")}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Näytä Testi Toast
        </button>
      </div> */}

      <div className={`bg-fixed bg-center bg-cover ${theme === "dark" ? "bg-[url('/src/assets/backgroundimage2.webp')]" : "bg-lightbg"}`}>
        <Routes>
        <Route path="/" element={<Home />} />
                    <Route path="/palvelut" element={<Rental />} />
                    <Route path="/UKK" element={<UKK />} />
                    <Route path="/meista" element={<AboutUs />} />
                    <Route path="/varauskalenteri" element={<Booking />} />
                    <Route path="/vuokrattavat/:laite" element={<Rental />} />
                    <Route path="/bilekaiutin-mikrofoneilla" element={<Partybox />} />
                    <Route path="/2000w-aanentoistopaketti" element={<PreSonus2000W />} />
                    <Route path="/300w-aanentoistopaketti" element={<Mackie300W />} />
                    <Route path="/625w-aanentoistopaketti" element={<JBL625W />} />
                    <Route path="/multiefekti-adj-stinger-ii" element={<ADJStinger2 />} />
                    <Route path="/savukone-adj-volcano" element={<ADJVolcano />} />
                    <Route path="/led-valot-chauvet-56-24c" element={<LEDLights />} />
                    <Route path="/ADJmyDMXGO" element={<ADJmyDMX />} />
                    <Route path="/fujifilm-instax-mini-12" element={<FujifilmInstaxMini12Page />} />
                    <Route path="/lavamonitori-db-fm12-600w" element={<DB600W />} />
                    <Route path="/SoundcraftFX16" element={<SoundcraftFX16 />} />
                    <Route path="/Tietosuojalausunto" element={<PrivacyPolicy />} />
                    
                    {/* Uusi reitti ADJ Stinger Spot 30:lle */}
                    <Route path="/adj-stinger-spot-30" element={<ADJStingerSpot30 />} />

                    
                    {/* NotFound-reitti kaikille muille poluille */}
                    <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      {/* Evästeilmoitus */}
      <CookieConsent
        location="bottom"
        style={{
          background: "#0F0240",
          textAlign: "center",
          opacity: "95%",
          fontFamily: "Outfit",
          boxShadow: "0px 13px 16px -1px rgba(0,0,0,0.75)",
        }}
        buttonStyle={{
          color: "#fafafa",
          background: "#3B0273",
          fontSize: "14px",
          margin: "5px",
          borderRadius: "10%",
        }}
        buttonText="Hyväksy"
        expires={365}
      >
        Tämä sivu käyttää evästeitä. Lue lisää{" "}
        <Link to="/Tietosuojalausunto" style={{ color: "#fafafa" }}>
          tietosuojalausunnostamme
        </Link>
        .
      </CookieConsent>

      <div className="progress_wrapper">
        <div className="progress_bar" id="bar"></div>
      </div>

      <FaArrowUp
        className={showButton ? "showButton" : "hidden"}
        onClick={scrollToTop}
      />
    </>
  );
}

export default App;
