// src/components/BookingCalendar/BookingCalendar.jsx
import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import fiLocale from "@fullcalendar/core/locales/fi";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Tippy.js tyylit
import "@fullcalendar/common/main.css"; // Varmista, että tämä vastaa FullCalendarin versiota
import "@fullcalendar/daygrid/main.css";
import "./BookingCalendar.css";
import DOMPurify from 'dompurify'; // Turvallinen HTML-puhdistus

const BookingCalendar = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  // Latausanimaatio
  useEffect(() => {
    if (loading || progress < 100) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 5;
        });
      }, 100);
      return () => clearInterval(interval);
    }
  }, [loading, progress]);

  // Hae varaukset API:sta
  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await fetch("https://spiderlegsproductions.com/api/get_reservations.php");
        if (!response.ok) {
          throw new Error(`Varauksien haku epäonnistui: ${response.status}`);
        }
        const data = await response.json();

        if (data.success && data.reservations) {
          const formattedEvents = data.reservations
            .map((reservation, index) => {
              if (!reservation.start) {
                console.warn(`Varaus ${reservation.varausID || `index-${index}`} puuttuu 'start' päivämäärältä.`);
                return null;
              }
              const start = new Date(reservation.start).toISOString();
              const end = reservation.end ? new Date(reservation.end).toISOString() : start;

              // Rakennetaan tuotteiden listaus HTML-muodossa
              const productsList = reservation.products.map(product => (
                `<li>${product.quantity} x ${product.tuote_nimi}</li>`
              )).join('');

              // Rakennetaan tooltip-sisältö HTML-muodossa ilman henkilökohtaisia tietoja
              const tooltipContent = `
                <strong>Tuotteet:</strong>
                <ul style="margin: 5px 0; padding-left: 20px;">
                  ${productsList}
                </ul>
                <strong>Ajanjakso:</strong> ${new Date(reservation.start).toLocaleDateString()} - ${new Date(reservation.end).toLocaleDateString()}
              `;

              return {
                id: reservation.varausID || `event-${index}`, // Varmista, että 'id' on olemassa ja uniikki
                title: "Varattu", // Näytetään vain "Varattu" kalenterissa
                start,
                end,
                extendedProps: {
                  tooltip: DOMPurify.sanitize(tooltipContent), // Puhdistettu HTML
                },
              };
            })
            .filter((event) => event !== null); // Poista puutteelliset tapahtumat

          console.log("Formatted Events:", formattedEvents); // Debugging
          setEvents(formattedEvents);
        } else {
          throw new Error(data.message || "Virhe varauksia hakiessa");
        }
      } catch (error) {
        console.error("Virhe palvelimeen yhdistettäessä:", error);
        setError("Varauskalenteria ei voitu ladata. Yritä myöhemmin uudelleen.");
      } finally {
        setLoading(false); // Lopeta latausanimaatio
      }
    };

    fetchReservations();
  }, []);

  // Debugging: Log events whenever they update
  useEffect(() => {
    console.log("Tapahtumat päivitetty:", events);
  }, [events]);

  // Lisää testitapahtuma, jos tapahtumia ei ole
  useEffect(() => {
    if (!loading && events.length === 0 && !error) {
      setEvents([
        {
          id: "test-event",
          title: "Testitapahtuma",
          start: new Date().toISOString(),
          end: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(), // 1 tunti kesto
        },
      ]);
    }
  }, [loading, events, error]);

  if (loading || progress < 100) {
    return (
      <div className="loading-container flex flex-col items-center justify-center h-screen">
        <div className="relative w-64 h-4 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-full overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-green-500 transition-all ease-linear"
            style={{ width: `${progress}%` }}
          />
        </div>
        <p className="mt-4 font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
          {progress}%
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container flex flex-col items-center justify-center h-screen">
        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
          {error}
        </p>
      </div>
    );
  }

  return (
    <div className="booking-calendar-container p-5">
      {/* Varausprosessi selitys */}
      <div className="mb-6 p-6 text-lg font-medium text-darkest dark:text-primary dark:bg-violet bg-white dark:bg-opacity-85 bg-opacity-75 rounded-lg shadow-md">
        <h2 className="font-customFont1 text-4xl font-bold mb-4">Näin teet varauksen</h2>
        <ol className="list-decimal font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal ml-6 space-y-2">
          <li>Valitse haluamasi laitteet ja lisää ne vuokrauskoriin.</li>
          <li>
            Tarkista vuokraustilanne kalenterista tai täytä lomake ja lähetä se.
            Saatavuus tarkistetaan automaattisesti lomakkeen lähettämisen yhteydessä.
          </li>
          <li>Täytä lomakkeeseen tarvittavat tiedot, kuten päivämäärät ja tapahtumapaikka.</li>
          <li>Vahvista alustava varaus vastaamalla sähköpostiin.</li>
          <li>Alustava varaus on voimassa 24 tuntia.</li>
        </ol>
        <p className="mt-4 font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
          Varauskalenterista näet jo tehdyt varaukset sekä vapaana olevat ajankohdat. Kalenterin avulla voit suunnitella vuokrauksesi helposti.
        </p>
        <p className="mt-4 font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
          Kerrothan lomakkeen viestikentässä, jos haluat meidän hoitavan koko tilaisuuden suunnittelun ja toteutuksen!
        </p>
      </div>

      {/* Kalenteri */}
      <div className="custom-calendar-wrapper rounded-lg shadow-md mx-auto dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          editable={false}
          locales={[fiLocale]}
          locale="fi"
          eventContent={(eventInfo) => {
            if (!eventInfo || !eventInfo.event) {
              return null; // Varmista, että tapahtumatiedot ovat olemassa
            }

            // Debugging: Tarkista tooltipin sisältö
            console.log("Tooltip content:", eventInfo.event.extendedProps.tooltip);

            return (
              <Tippy content={<div dangerouslySetInnerHTML={{ __html: eventInfo.event.extendedProps?.tooltip || "Varattu" }} />} placement="top" animation="scale">
                <div className="fc-event-content">
                  {eventInfo.event.title}
                </div>
              </Tippy>
            );
          }}
        />
      </div>

      {/* ToastContainer */}
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        aria-live="assertive" // Parantaa saavutettavuutta
      /> */}
    </div>
  );
};

export default BookingCalendar;
