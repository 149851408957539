// src/TextToSpeech/TextToSpeech.jsx
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BsPlayFill, BsStopFill } from "react-icons/bs";
import { HiSpeakerWave } from "react-icons/hi2";

const TextToSpeech = ({ text }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    if (!window.speechSynthesis) {
      console.error("Speech Synthesis API ei ole tuettu tässä selaimessa.");
      return;
    }

    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    u.lang = "fi-FI";

    u.addEventListener("end", () => {
      setIsPlaying(false);
    });

    setUtterance(u);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handleToggle = () => {
    if (!utterance) {
      console.error("Utterance-objekti ei ole alustettu oikein.");
      return;
    }

    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    } else {
      window.speechSynthesis.speak(utterance);
      setIsPlaying(true);
    }
  };

  const handleStop = () => {
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel();
    }
    setIsPlaying(false);
  };

  return (
    <div className="flex items-center space-x-2 py-2">
      <button
        onClick={handleToggle}
        className="text-darkest dark:text-primary rounded-md p-2 border-2 dark:bg-violet bg-lightbg dark:hover:border-2 dark:border-primary hover:bg-white hover:bg-opacity-60 dark:hover:bg-dark dark:hover:bg-opacity-75 border-darkest"
        aria-label={isPlaying ? "Pysäytä teksti" : "Kuuntele teksti"}
      >
        {isPlaying ? (
          <BsStopFill className="text-sm" />
        ) : (
          <BsPlayFill className="text-sm" title="Kuuntele teksti" />
        )}
      </button>
      <button
        onClick={handleStop}
        className="text-darkest dark:text-lightbg font-customFont1 rounded-md"
        aria-label="Pysäytä puhe"
      >
        <HiSpeakerWave
          className="text-2xl cursor-text mx-2 text-white text-opacity-75 drop-shadow-[0px_0px_5px_rgba(0,0,0,0.80)]"
        />
      </button>
    </div>
  );
};

TextToSpeech.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextToSpeech;
