// src/Pages/Contactpage/Contactpage.jsx
import React, { useState, useEffect, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import BookingCalendar from "../../components/BookingCalendar/BookingCalendar";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const libraries = ["places"];

const Contactpage = () => {
  // Ympäristömuuttujat
  const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID_OWNER = process.env.REACT_APP_TEMPLATE_ID_OWNER;
  const EMAILJS_TEMPLATE_ID_CUSTOMER = process.env.REACT_APP_TEMPLATE_ID_CUSTOMER;
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const HOME_ADDRESS = process.env.REACT_APP_HOME_ADDRESS;
  const KILOMETER_RATE = parseFloat(process.env.REACT_APP_KILOMETER_RATE) || 0.30;
  const VARAUSMAKSU = 50; // Varausmaksu määriteltynä

  // Tilat
  const [cart, setCart] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [nimi, setNimi] = useState("");
  const [sahkoposti, setSahkoposti] = useState("");
  const [puhelinnumero, setPuhelinnumero] = useState("");
  const [tapahtumapaikka, setTapahtumapaikka] = useState("");
  const [viesti, setViesti] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formError, setFormError] = useState(null);
  const [distance, setDistance] = useState(null);
  const [travelCost, setTravelCost] = useState(null);
  const [needsTransport, setNeedsTransport] = useState(false);
  const [events, setEvents] = useState([]); // Lisätty tila tapahtumille
  const autocompleteRef = useRef(null);
  const recaptchaRef = useRef(null); // Lisätty ref reCAPTCHA:lle

  // Funktio vuokraushinnan laskemiseen
  const calculateRentalPrice = (startDate, endDate, pricePerDay, quantity) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    const totalPrice = days * pricePerDay * quantity;
    return totalPrice;
  };

  // Lisää Google Autocomplete-komponenttiin rajoitukset Suomeen
  const autocompleteOptions = {
    componentRestrictions: { country: "fi" }, // Vain Suomi
  };

  // Lähetetään tuotteiden ID:t ja määrät tietokantaan
  const cartDataForDB = cart.map(item => ({
    id: item.id,          // Käytetään 'id' avainta
    quantity: item.quantity
  }));

  // Ladataan cart paikallisesta tallennuksesta komponentin latautuessa
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("rentalCart")) || [];
    setCart(cartItems);
  }, []);

  // Kuuntelee 'cartUpdated' tapahtumaa ja päivittää cart-tilan
  useEffect(() => {
    const handleCartUpdate = () => {
      const newCart = JSON.parse(localStorage.getItem("rentalCart")) || [];
      console.log("Cart updated event received:", newCart);
      setCart(newCart);
    };

    window.addEventListener("cartUpdated", handleCartUpdate);

    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, []);

  // Poistetaan tuote korista
  const handleRemoveFromCart = (index) => {
    try {
      const updatedCart = [...cart];
      const removedItem = updatedCart.splice(index, 1)[0];
      setCart(updatedCart);
      localStorage.setItem("rentalCart", JSON.stringify(updatedCart));
      console.log("Removed item:", removedItem);
      
      // Dispatch the cartUpdated event
      window.dispatchEvent(new Event("cartUpdated"));
      console.log("cartUpdated event dispatched");

      // Näytä toast-viesti
      toast.info(`Tuote ${removedItem.name} poistettu vuokrauskorista.`, {
        toastId: `remove-${removedItem.id}-${Date.now()}`
      });
    } catch (error) {
      console.error("Tuotteen poistaminen koriin epäonnistui:", error);
      toast.error("Tuotteen poistaminen koriin epäonnistui. Yritä myöhemmin.", {
        toastId: `error-remove-${Date.now()}`
      });
    }
  };

  // Haetaan olemassa olevat varaukset tapahtumakalenteria varten
  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await fetch("https://spiderlegsproductions.com/api/get_reservations.php");
        if (!response.ok) {
          throw new Error(`API-virhe! Koodi: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        console.log("Tapahtumat:", result.reservations);

        if (result.success) {
          setEvents(result.reservations.map((reservation, index) => ({
            id: `event-${index}`,
            title: reservation.title || "Varattu",  // Varmistetaan, että title ei ole undefined
            start: reservation.start,
            end: reservation.end,
            extendedProps: {
              name: reservation.name,
              location: reservation.location,
              message: reservation.message,
              products: reservation.products // Voit lisätä tuotteet extendedProps:iin, jos haluat
            }
          })));
        }
      } catch (error) {
        console.error("Virhe API-kutsussa:", error);
        toast.error("Tapahtumien lataaminen epäonnistui.");
      }
    };

    fetchReservations();
  }, []);

  // Käsitellään kuljetuksen valinta
  const handleTransportChange = (event) => {
    setNeedsTransport(event.target.value === "yes");
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        setTapahtumapaikka(place.formatted_address);
        calculateDistance(place.geometry.location);
      }
    }
  };

  const calculateDistance = (destinationLocation) => {
    const service = new window.google.maps.DistanceMatrixService();
    console.log("Lasketaan matkaa osoitteesta:", HOME_ADDRESS, "osoitteeseen:", destinationLocation);
  
    service.getDistanceMatrix(
      {
        origins: [HOME_ADDRESS],
        destinations: [destinationLocation],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        console.log("Distance Matrix API vastaus:", response);
        console.log("API status:", status);
  
        if (status === "OK") {
          const element = response.rows[0].elements[0];
          console.log("Elementin status:", element.status);
  
          if (element.status === "OK") {
            const distanceInKm = element.distance.value / 1000;
            setDistance(distanceInKm);
  
            const cost = distanceInKm * KILOMETER_RATE;
            setTravelCost(cost.toFixed(2));
            console.log(`Laskettu etäisyys: ${distanceInKm} km, kustannus: ${cost.toFixed(2)} €`);
          } else {
            console.error("Matkan laskeminen epäonnistui elementin status:", element.status);
            setFormError(`Matkan laskeminen epäonnistui. Google Maps API palautti statuksen: ${element.status}`);
            setDistance(null);
            setTravelCost(null);
          }
        } else {
          console.error("Distance Matrix API epäonnistui status:", status);
          setFormError(`Matkan laskeminen epäonnistui. Google Maps API palautti statuksen: ${status}`);
          setDistance(null);
          setTravelCost(null);
        }
      }
    );
  };

  const checkAvailability = async () => {
    if (!Array.isArray(cart)) {
      console.error("Cart ei ole taulukko.");
      setFormError("Lomake on virheellinen. Yritä uudelleen.");
      return false;
    }
  
    const devices = cart.map((item) => item.id);
  
    if (devices.length === 0) {
      console.error("Ei valittuja laitteita.");
      setFormError("Valitse laitteet ennen varauksen tekemistä.");
      return false; // Palataan, jos laitteita ei ole valittu
    }
  
    const isValidDate = (date) => !isNaN(Date.parse(date));
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
      console.error("Virheellinen päivämäärämuoto.");
      setFormError("Syötä kelvolliset päivämäärät.");
      return false;
    }
  
    if (new Date(startDate) > new Date(endDate)) {
      console.error("Aloituspäivämäärä on myöhempi kuin lopetuspäivämäärä.");
      setFormError("Aloituspäivämäärä ei voi olla myöhempään kuin lopetuspäivämäärä.");
      return false;
    }
  
    try {
      const response = await fetch("https://spiderlegsproductions.com/api/get_reservations.php");
  
      if (!response.ok) {
        throw new Error(`API-virhe! Koodi: ${response.status} - ${response.statusText}`);
      }
  
      const result = await response.json();
  
      if (result.success) {
        const existingReservations = result.reservations; // API:n palauttamat varaukset
  
        const isOverlapping = existingReservations.some((reservation) => {
          const startOverlap = new Date(startDate) < new Date(reservation.end);
          const endOverlap = new Date(endDate) > new Date(reservation.start);
          // Tässä oletetaan, että reservation.products sisältää tuotteiden ID:t
          return startOverlap && endOverlap && reservation.products.some(product => devices.includes(product.tuoteID));
        });
  
        if (isOverlapping) {
          setFormError("Laitteet eivät ole saatavilla valitulla ajanjaksolla.");
          return false; // Jos löytyy päällekkäisyys
        }
  
        return true;
      } else {
        throw new Error("API ei palauttanut onnistunutta tulosta.");
      }
    } catch (error) {
      console.error("API-virhe:", error);
      setFormError("Laitteiden saatavuuden tarkistus epäonnistui: " + error.message);
      return false;
    }
  };  

  // Muotoilee päivämäärät suomenkieliseksi DD.MM.YYYY
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Kuukaudet ovat 0-indexoituja
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // Lähettää varauslomakkeen tiedot
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFormError(null); // Tyhjennä aiemmat virheet

    if (!recaptchaValue) {
      toast.error("Varmista, että olet ihminen!", {
        toastId: `recaptcha-error-${Date.now()}`
      });
      return;
    }

    // Jos kuljetus tarvitaan, varmista että matka ja kustannukset on laskettu
    if (needsTransport && (!distance || !travelCost)) {
      toast.error("Matkan laskeminen tapahtumapaikkaan epäonnistui.", {
        toastId: `transport-error-${Date.now()}`
      });
      return;
    }

    // Tarkista laitteiden saatavuus
    const isAvailable = await checkAvailability();
    if (!isAvailable) {
      toast.error("Laite ei ole saatavilla valitulla ajanjaksolla.", {
        toastId: `availability-error-${Date.now()}`
      });
      return; // Lopeta, jos laite ei ole saatavilla
    }

    const formData = {
      nimi,
      sahkoposti,
      puhelinnumero,
      tapahtumapaikka,
      start_date: startDate,
      end_date: endDate,
      cart: cartDataForDB,  // Tämä lähettää tuotteiden ID:t ja määrät
      viesti,
    };

    // Lisää tämä logi varmistaaksesi, että data on oikein muotoiltu
    console.log("Lähetettävä formData:", JSON.stringify(formData, null, 2));

    try {
      // Lähetä tiedot tietokantaan
      const response = await fetch("https://spiderlegsproductions.com/api/save_reservation.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API-virhe: ${response.status} - ${errorText}`);
      }

      const saveResult = await response.json();

      if (!saveResult.success) {
        toast.error(saveResult.message, {
          toastId: `save-error-${Date.now()}`
        }); // Näytetään virhe toastina
        return;
      }

      // Asiakkaalle lähetettävä kokonaishinta (ilman matkakustannuksia)
      const totalCustomerPrice = cart.reduce(
        (total, item) => total + calculateRentalPrice(startDate, endDate, item.price, item.quantity),
        0
      );

      const remainingAmount = totalCustomerPrice - VARAUSMAKSU; // Tilaisuuden jälkeen maksettava summa

      // Sinulle lähetettävä kokonaishinta (sisältää matkakustannukset)
      const totalOwnerPrice =
        cart.reduce((total, item) => total + item.price * item.quantity, 0) +
        (needsTransport ? parseFloat(travelCost) : 0) +
        VARAUSMAKSU;

      // Formatoi päivämäärät
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      const formatted_date = `${formattedStartDate} - ${formattedEndDate}`;

      // Lisää tämä templateParamsCustomer-objektiin
      const templateParamsCustomer = {
        user_name: nimi,
        user_email: sahkoposti,
        formatted_date: formatted_date,
        place: tapahtumapaikka,
        cart_summary: cart.map(item => `${item.name} (x${item.quantity})`).join(", "), // Korvaus
        total_price: totalCustomerPrice.toFixed(2),
        reservation_fee: VARAUSMAKSU.toFixed(2),
        remaining_amount: remainingAmount.toFixed(2),
        user_message: viesti,
      };
      

      const templateParamsOwner = {
        user_name: nimi,
        user_email: sahkoposti,
        user_phone: puhelinnumero,
        formatted_date: formatted_date,
        place: tapahtumapaikka,
        cart_summary: cart.map(item => `${item.name} (x${item.quantity})`).join(", "), // Korvaus
        distance: needsTransport ? distance : "Ei kuljetusta",
        travel_cost: needsTransport ? travelCost : "0",
        total_price: totalOwnerPrice.toFixed(2),
        user_message: viesti,
      };

      // Lisää logit varmistaaksesi, että muuttujat ovat oikein
      console.log("Lähetetään sähköposti asiakkaalle:", templateParamsCustomer);
      console.log("Lähetetään sähköposti omistajalle:", templateParamsOwner);

      // Lähetä molemmat sähköpostit
      const sendEmailToCustomer = emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID_CUSTOMER,
        templateParamsCustomer,
        EMAILJS_USER_ID
      );

      const sendEmailToOwner = emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID_OWNER,
        templateParamsOwner,
        EMAILJS_USER_ID
      );

      // Odota molempien sähköpostien lähettämistä
      const [customerResult, ownerResult] = await Promise.all([sendEmailToCustomer, sendEmailToOwner]);

      console.log("Asiakkaan sähköposti status:", customerResult.status);
      console.log("Omistajan sähköposti status:", ownerResult.status);

      if (customerResult.status === 200 && ownerResult.status === 200) {
        toast.success("Alustava varaus onnistui! Vahvistusviesti on lähetetty sähköpostiisi. Jos viestiä ei kuulu, tarkista roskapostisi", {
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "colored",
        });

        // Tyhjennä lomake
        setNimi("");
        setSahkoposti("");
        setPuhelinnumero("");
        setTapahtumapaikka("");
        setViesti("");
        setRecaptchaValue(null);
        if (recaptchaRef.current) recaptchaRef.current.reset(); // Nollaa reCAPTCHA
        // Tyhjennä koritiedot
        setCart([]);
        localStorage.removeItem("rentalCart");

        // Dispatch the cartUpdated event to inform other components
        window.dispatchEvent(new Event("cartUpdated"));
        console.log("cartUpdated event dispatched after successful reservation");
      } else {
        console.error("EmailJS status:", customerResult.status, customerResult.text, ownerResult.status, ownerResult.text);
        toast.error("Sähköpostin lähettäminen epäonnistui. Yritä myöhemmin uudelleen.", {
          toastId: `emailjs-error-${Date.now()}`
        });
      }
    } catch (error) {
      console.error("Virhe lomakkeen käsittelyssä:", error);
      if (error.status && error.text) {
        console.error("EmailJS Error Status:", error.status);
        console.error("EmailJS Error Text:", error.text);
      }
      toast.error("Tietojen lähettäminen epäonnistui. Tarkista yhteys tai kokeile uudelleen.", {
        toastId: `submit-error-${Date.now()}`
      });
    }
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <div className="w-full mx-auto px-0"> {/* Poistettu container ja asetettu px-0 */}
        
        {/* Lisää ToastContainer täällä */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <h1 className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-5xl sm:text-6xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold text-center">
          Varauskalenteri
        </h1>

        {/* Päägridi: Varauslomake ja Vuokrauskori */}
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4"> {/* Muutettu gap-8 -> gap-4 */}
          {/* Varauslomake */}
          <form
            onSubmit={handleFormSubmit}
            className="order-2 space-y-4 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-lg shadow-md p-4 sm:p-6 mb-4"
          >
            <h2 className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center">
              Varauslomake
            </h2>
            {/* Nimi */}
            <div>
              <label
                htmlFor="name"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Nimesi:
              </label>
              <input
                id="name"
                type="text"
                placeholder="Etu- ja sukunimi.."
                value={nimi}
                onChange={(e) => setNimi(e.target.value)}
                required
                className="w-full p-2 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* Sähköposti */}
            <div>
              <label
                htmlFor="email"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Sähköposti:
              </label>
              <input
                id="email"
                type="email"
                placeholder="Syötä sähköpostisi.."
                value={sahkoposti}
                onChange={(e) => setSahkoposti(e.target.value)}
                required
                className="w-full p-2 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* Puhelinnumero */}
            <div>
              <label
                htmlFor="puhelinnumero"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Puhelinnumero:
              </label>
              <input
                id="puhelinnumero"
                type="tel"
                placeholder="Anna puhelinnumero.."
                value={puhelinnumero}
                onChange={(e) => setPuhelinnumero(e.target.value)}
                required
                className="w-full p-2 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* Tapahtumapaikka */}
            <div>
              <label
                htmlFor="location"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Tapahtumapaikka:
              </label>
              <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={onPlaceChanged}
                options={autocompleteOptions} // Asetetaan rajoitukset
              >
                <div className="relative">
                  <input
                    id="location"
                    type="text"
                    placeholder="Etsi.."
                    value={tapahtumapaikka}
                    onChange={(e) => setTapahtumapaikka(e.target.value)}
                    required
                    className="w-full p-2 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg"
                  />
                </div>
              </Autocomplete>
            </div>

            {/* Kuljetusvalinta */}
            <div>
              <label
                htmlFor="needsTransport"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Tarvitsetko kuljetuksen?
              </label>
              <div className="flex space-x-4">
                {/* Kyllä vaihtoehto */}
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="needsTransport"
                    value="yes"
                    onChange={handleTransportChange}
                    className="form-radio text-blue-500 focus:ring-blue-300"
                  />
                  <span className="text-base sm:text-lg font-customFont1 text-shadow-lg text-darkest dark:text-primary">
                    Kyllä
                  </span>
                </label>

                {/* Ei vaihtoehto */}
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="needsTransport"
                    value="no"
                    onChange={handleTransportChange}
                    className="form-radio text-red-500 focus:ring-red-300"
                  />
                  <span className="text-base sm:text-lg font-customFont1 text-shadow-lg text-darkest dark:text-primary">
                    En
                  </span>
                </label>
              </div>
            </div>

            {/* Päivämäärät */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="relative">
                <label
                  htmlFor="startDate"
                  className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
                >
                  Vuokraus alkaa:
                </label>
                <input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                  className="w-full rounded-lg bg-white border-none focus:outline-none" 
                  placeholder="pp.kk.vvvv"
                />
              </div>
              <div className="relative">
                <label
                  htmlFor="endDate"
                  className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
                >
                  Vuokraus päättyy:
                </label>
                <input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                  className="w-full rounded-lg bg-white border-none focus:outline-none" 
                  placeholder="pp.kk.vvvv"
                />
              </div>
            </div>

            {/* Viesti */}
            <div>
              <label
                htmlFor="message"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Viesti:
              </label>
              <textarea
                id="message"
                placeholder="Kirjoita tähän viestisi.."
                value={viesti}
                onChange={(e) => setViesti(e.target.value)}
                className="w-full h-40 p-3 sm:p-4 rounded-lg bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* ReCAPTCHA */}
            <div>
              <ReCAPTCHA
                sitekey="6LfXvq0qAAAAAMiKAKM63SjCTSJhgUBcjEtwhfuV"
                onChange={(value) => setRecaptchaValue(value)}
                ref={recaptchaRef}
              />
            </div>

            {/* Virheilmoitus */}
            {formError && (
              <p className="text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2">
                {formError}
              </p>
            )}

            {/* Lähetä varaus -painike */}
            <button
              type="submit"
              className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-2xl sm:text-3xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer"
            >
              Lähetä varaus
            </button>
          </form>

          {/* Vuokrauskori */}
          <div className="order-3 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-lg shadow-md p-4 mb-4">
            <h2 className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-left mb-4">
              Vuokrauskorissa
            </h2>
            <ul>
              {cart.map((item, index) => (
                <li key={index} className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-sm sm:text-lg md:text-lg lg:text-lg xl:text-xl font-normal">
                      {item.name} (x{item.quantity})
                    </p>
                  </div>
                  <div className="flex items-center">
                    {/* Lasketaan kokonaishinta, jos startDate ja endDate ovat valittuja */}
                    <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-sm sm:text-lg md:text-lg lg:text-lg xl:text-xl font-normal pr-4">
                      {startDate && endDate ? calculateRentalPrice(startDate, endDate, item.price, item.quantity).toFixed(2) : (item.price * item.quantity).toFixed(2)} €
                    </p>
                    <button
                      onClick={() => handleRemoveFromCart(index)}
                      className="h-10 w-16 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-sm overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in-out items-center rounded-full cursor-pointer"
                      title="Poista tuote"
                    >
                      Poista
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <p className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-4xl sm:text-5xl lg:text-5xl xl:text-6xl font-bold text-right">
              Yhteensä: {cart.reduce((total, item) => {
                if (startDate && endDate) {
                  return total + calculateRentalPrice(startDate, endDate, item.price, item.quantity);
                } else {
                  return total + item.price * item.quantity;
                }
              }, 0).toFixed(2)} €
            </p>
          </div>

          {/* Varauskalenteri */}
          <div className="md:col-span-2 md:w-full order-1 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-lg shadow-md p-4 sm:p-6">
            <BookingCalendar events={events} /> {/* Välitetty events-tila */}
          </div>
        </div>

        {/* Alla varaukset poistettu */}
        {/* <div className="mt-8">
          <h2 className="text-3xl font-bold mb-4">Kaikki Varaukset</h2>
          {reservations.length === 0 ? (
            <p>Ei varauksia.</p>
          ) : (
            reservations.map(reservation => (
              <div key={reservation.varausID} className="mb-6 p-4 bg-gray-100 rounded-lg shadow">
                <h3 className="text-2xl font-bold">Varaus ID: {reservation.varausID}</h3>
                <p><strong>Nimi:</strong> {reservation.name}</p>
                <p><strong>Sähköposti:</strong> {reservation.sahkoposti}</p>
                <p><strong>Puhelinnumero:</strong> {reservation.puhelinnumero}</p>
                <p><strong>Tapahtumapaikka:</strong> {reservation.location}</p>
                <p><strong>Aloituspäivämäärä:</strong> {reservation.start}</p>
                <p><strong>Lopetuspäivämäärä:</strong> {reservation.end}</p>
                <p><strong>Viesti:</strong> {reservation.message}</p>
                <h4 className="text-xl font-semibold mt-4">Tuotteet:</h4>
                <ul className="list-disc list-inside">
                  {reservation.products.map(product => (
                    <li key={product.tuoteID}>
                      {product.tuote_nimi} - Määrä: {product.quantity}
                    </li>
                  ))}
                </ul>
              </div>
            ))
          )}
        </div> */}
        
        <Footer />
      </div>
    </LoadScript>
  );
};

export default Contactpage;
