// src/components/Testimonial/Testimonial.jsx
import React from 'react';
import Slider from 'react-slick';
import FeedbackCard from './FeedbackCard';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../Testimonial/Testimonial.css";

const Testimonial = () => {
  const testimonials = [
    {
      quote: "Spider Legs Productionsin äänentoistopalvelut ovat ylittäneet odotukseni! Tapahtumamme sujui moitteettomasti.",
      author: "Matti Meikäläinen",
      position: "Tapahtumajärjestäjä",
      avatar: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
      quote: "Erinomainen asiakaspalvelu ja laadukas laitteisto. Suosittelen lämpimästi kaikille, jotka tarvitsevat luotettavaa äänentoistoa.",
      author: "Liisa Esimerkki",
      position: "Yrityksen HR Manager",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
      quote: "Spider Legs Productions tarjosi juuri oikeanlaisen äänentoiston festivaaliimme. Kaikki meni suunnitelmien mukaan.",
      author: "Pekka Testaaja",
      position: "Festivaalipäällikkö",
      avatar: "https://randomuser.me/api/portraits/men/65.jpg",
    },
    {
      quote: "Helppo käyttää ja erinomainen tuki tiimiltä! Kiitos Spider Legs Productions onnistuneesta esitystekniikasta.",
      author: "Anna Testaaja",
      position: "Esityksen vetäjä",
      avatar: "https://randomuser.me/api/portraits/women/50.jpg",
    },
    {
      quote: "Laadukas äänentoisto ja ammattitaitoinen henkilökunta. Spider Legs Productions teki tapahtumastamme ikimuistoisen.",
      author: "Juha Virtanen",
      position: "Markkinointipäällikkö",
      avatar: "https://randomuser.me/api/portraits/men/45.jpg",
    },
    {
      quote: "Spider Legs Productionsin vuokrauspalvelut olivat ratkaisevassa roolissa yrityksemme konferenssissa. Suosittelen!",
      author: "Kaisa Nieminen",
      position: "Konferenssipäällikkö",
      avatar: "https://randomuser.me/api/portraits/women/55.jpg",
    },
    {
      quote: "Erittäin tyytyväinen Spider Legs Productionsin tarjoamiin äänentoistopalveluihin. Kaikki toimi täydellisesti.",
      author: "Timo Laaksonen",
      position: "Tapahtumakoordinaattori",
      avatar: "https://randomuser.me/api/portraits/men/22.jpg",
    },
    {
      quote: "Loistava laitteisto ja nopea asennus. Spider Legs Productions teki työni helpoksi.",
      author: "Sara Koskinen",
      position: "Esitysorganisaattori",
      avatar: "https://randomuser.me/api/portraits/women/60.jpg",
    },
    {
      quote: "Spider Legs Productionsin äänentoistopalvelut paransivat esityksemme laatua merkittävästi. Kiitos!",
      author: "Mikko Miettinen",
      position: "Kulttuurijohtaja",
      avatar: "https://randomuser.me/api/portraits/men/18.jpg",
    },
    {
      quote: "Ammattitaitoinen palvelu ja huippuluokan laitteet. Spider Legs Productions on luotettava kumppani.",
      author: "Elina Salonen",
      position: "Tapahtumatuottaja",
      avatar: "https://randomuser.me/api/portraits/women/35.jpg",
    },
    {
      quote: "Spider Legs Productionsin tiimi varmisti, että kaikki sujui ongelmitta. Suosittelen heidän palveluitaan kaikille.",
      author: "Ville Korhonen",
      position: "Yrityksen toimitusjohtaja",
      avatar: "https://randomuser.me/api/portraits/men/50.jpg",
    },
    {
      quote: "Upea äänenlaatu ja nopea toimitus. Spider Legs Productions teki tapahtumastamme menestyksen.",
      author: "Laura Heikkinen",
      position: "Tapahtumanjärjestäjä",
      avatar: "https://randomuser.me/api/portraits/women/25.jpg",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4, // Näyttää kaksi testiota samanaikaisesti
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024, // Näyttää yhden testion pienemmillä näytöillä
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 768, // Näyttää yhden testion pienemmillä näytöillä
        settings: {
          slidesToShow: 1,
        }
      }
    ],
    fade: false, // Ei sulavaa siirtymää, koska näytetään useita
    cssEase: "linear",
  };

  return (
    <section className="container min-h-[620px] flex dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-3xl pb-10 p-5 mb-20">
      <div className="container mx-auto px-4">
        {/* Otsikko ja Kuvaus */}
        <div className="mb-8 text-center">
          <h2 className="font-customFont2 text-shadow-lg text-center text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight">
            Asiakaspalautteet
          </h2>
          <p className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
            Lue mitä asiakkaamme sanovat palveluistamme ja miksi Spiderlegs productions on heidän valintansa äänentoiston-ja esitystekniikan vuokrauksessa.
          </p>
        </div>

        {/* Karuselli */}
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <FeedbackCard
                quote={testimonial.quote}
                author={testimonial.author}
                position={testimonial.position}
                avatar={testimonial.avatar}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonial;
