// src/components/OtherRentals/OtherRentals.jsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextToSpeech from "../../TextToSpeech/textToSpeech";
// import { toast } from "react-toastify"; // Kommentoitu pois toastify-importti
import AOS from "aos";
import "aos/dist/aos.css"; // Importoi AOS CSS

// Apufunktiot ostoskorin hallintaan
const getCart = () => {
  return JSON.parse(localStorage.getItem("rentalCart")) || [];
};

const isCategoryInCart = (category) => {
  const cart = getCart();
  return cart.some((item) => item.category === category);
};

const OtherRentals = () => {
  const [otherRentalData, setOtherRentalData] = useState([]);
  const [error, setError] = useState(null);
  const [cart, setCart] = useState(getCart()); // Reaktiivinen tila korille

  // Funktio muiden vuokrattavien tuotteiden hakemiseen
  const fetchOtherRentals = async () => {
    try {
      const category = encodeURIComponent("muut"); // Varmista URL-koodaus
      const response = await fetch(
        `https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=${category}`
      );

      if (!response.ok) {
        throw new Error(`Palvelin palautti virheen: ${response.status}`);
      }
      const data = await response.json();

      // Varmista, että 'categories' ja 'Muut' ovat olemassa
      if (data.success && data.categories && Array.isArray(data.categories.Muut)) {
        setOtherRentalData(data.categories.Muut);
      } else {
        throw new Error("API:n vastausrakenne ei ole odotettu.");
      }
    } catch (err) {
      console.error("Virhe tietojen haussa:", err);
      setError("Tietojen lataus epäonnistui. Yritä myöhemmin uudelleen.");
      // toast.error(errorMessage, { position: "top-right" }); // Kommentoitu pois toast-error
    }
  };

  // Lisää tuote koriin
  const addToCart = (product) => {
    try {
      // Estä saman kategorian tuotteiden lisääminen
      if (isCategoryInCart("Muut")) { // Muutettu "muut" -> "Muut"
        // toast.warn(`Vain yksi "Muut"-kategorian tuote voi olla korissa kerrallaan.`, {
        //   position: "top-right",
        // });
        return;
      }

      if (product.available <= 0) {
        // toast.error(`Tuote ${product.name} on loppunut varastosta.`, {
        //   position: "top-right",
        // });
        return;
      }

      // Luo uusi tuoteobjekti lisättäväksi koriin
      const productToAdd = {
        id: product.id,
        name: product.name,
        price: product.price,
        quantity: 1,
        category: "Muut", // Muutettu "muut" -> "Muut"
      };

      const updatedCart = [...cart, productToAdd];
      localStorage.setItem("rentalCart", JSON.stringify(updatedCart));
      setCart(updatedCart);
      window.dispatchEvent(new Event("cartUpdated"));

      // toast.success(`${product.name} lisätty vuokrauskoriin!`, {
      //   
      // }); // Kommentoitu pois toast-success
    } catch (error) {
      console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
      // toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin uudelleen.", {
      //   position: "top-right",
      // }); // Kommentoitu pois toast-error
    }
  };

  useEffect(() => {
    // Alusta AOS
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });

    fetchOtherRentals();

    // Kuuntele ostoskorin päivityksiä
    const handleCartUpdate = () => {
      setCart(getCart());
    };

    window.addEventListener("cartUpdated", handleCartUpdate);
    return () => {
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, []); // Poistettu cartItems riippuvuus

  return (
    <div
      id="muut-vuokrattavat-tuotteet"
      className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 pl-5 pr-5 rounded-3xl cursor-pointer p-5 my-10"
    >
      <div className="container mx-auto">
        {/* Sivun otsikko */}
        <div className="flex flex-row items-center flex-wrap">
          <h2 className="text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight text-left font-customFont2 text-darkest dark:text-primary text-shadow-lg">
            MUUT VUOKRATTAVAT TUOTTEET
          </h2>
        </div>

        {/* Sivun kuvaus */}
        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
          Tähän osioon on koottu muita tuotteita tai palveluita, joita myymme tai
          tarjoamme vuokralle. Esittelyssä ensimmäisenä hauska Fujifilm Instax Mini-12
          -kompaktikamera.
        </p>

        {/* Virheilmoitus */}
        {error ? (
          <div className="text-red-500 font-bold text-center mt-5">{error}</div>
        ) : (
          <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {otherRentalData.map((item) => (
              <div
                key={item.id}
                className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between"
                data-aos="fade-up" // Lisää AOS-animaatio
              >
                <TextToSpeech text={item.description || "Ei kuvausta saatavilla."} />
                {/* Kuvan sisältö */}
                <div className="w-full h-[120px]">
                  <Link
                    to={item.link || "#"}
                    title={item.name}
                    onClick={() => {
                      if (item.link) {
                        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                      }
                    }}
                    aria-label={`Näytä ${item.name} tuotekuvaus`}
                  >
                    <img
                      src={item.image || "assets/images/placeholder.jpg"}
                      alt={item.name || "Ei kuvaa saatavilla"}
                      title={item.name || "Ei kuvaa saatavilla"}
                      loading="lazy"
                      className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]"
                    />
                  </Link>
                </div>

                <div className="flex flex-col justify-between flex-grow">
                  <div className="grid grid-cols-1 place-items-center pt-10">
                    <h3
                      className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5"
                      title={item.name}
                    >
                      {item.name || "Tuntematon tuote"}
                    </h3>
                  </div>

                  <div className="mt-auto text-center">
                    <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                      <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal">
                        {item.price ? `${item.price} € / 24h` : "Hinta ei saatavilla"}
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <Link
                        to={item.link || "#"}
                        title="Katso lisätiedot"
                        onClick={() => {
                          if (item.link) {
                            window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                          }
                        }}
                        aria-label={`Katso lisätiedot ${item.name}`}
                      >
                        <button
                          className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75"
                          title="Katso lisätiedot"
                        >
                          Lisätiedot
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                {/* Lisää "Lisää koriin" -nappi */}
                <div className="grid place-content-center mt-4">
                  <button
                    title={isCategoryInCart("Muut") ? "Lisätty koriin" : "Lisää vuokrauskoriin"} // Muutettu "muut" -> "Muut"
                    onClick={() => {
                      if (!isCategoryInCart("Muut")) { // Muutettu "muut" -> "Muut"
                        addToCart({
                          id: item.id,
                          name: item.name,
                          price: item.price,
                          available: item.available,
                        });
                      }
                    }}
                    disabled={isCategoryInCart("Muut")} // Muutettu "muut" -> "Muut"
                    className={`h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                      isCategoryInCart("Muut") ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                  >
                    {isCategoryInCart("Muut") ? "Lisätty koriin" : "Lisää koriin"}
                    </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherRentals;