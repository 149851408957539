// src/components/MostPopular/MostPopular.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import TextToSpeech from "../../TextToSpeech/textToSpeech"; // Kommentoitu pois
// import { toast } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';

const getCart = () => JSON.parse(localStorage.getItem("rentalCart")) || [];

const MostPopular = () => {
  const [mostPopular, setMostPopular] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [cartItems, setCartItems] = useState(getCart());

  // const text =
  //   "Vuokratuimmat. Tutustu suosituimpiin ja vuokratuimpiin laitteisiimme. Näiden laadukkaiden tuotteiden avulla varmistat onnistuneen tapahtuman, olipa kyseessä bileet, teemabileet tai muu erityistilaisuus. Katso kaikki vuokrattavat klikkaamalla katso kaikki nappia.";

  // Funktio suosituimpien tuotteiden hakemiseen
  const fetchMostPopular = async () => {
    try {
      const devicesResponse = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
      if (!devicesResponse.ok) {
        throw new Error(`Laitteiden haku epäonnistui: ${devicesResponse.status}`);
      }
      const devicesData = await devicesResponse.json();

      if (devicesData.success && devicesData.categories) {
        // Varmista, että kategoriat on nimetty oikein ja on olemassa
        const categoriesToInclude = ["Äänentoisto", "Esitystekniikka", "Muut"];
        let allDevices = [];

        categoriesToInclude.forEach(category => {
          if (devicesData.categories[category] && Array.isArray(devicesData.categories[category])) {
            allDevices = allDevices.concat(devicesData.categories[category]);
          }
        });

        // Suodatetaan tuotteet, joilla on vuokrauskertoja ja järjestetään ne
        const sortedItems = allDevices
          .filter((device) => Number(device.rentals) > 0)
          .sort((a, b) => Number(b.rentals) - Number(a.rentals));

        if (sortedItems.length > 0) {
          setMostPopular(sortedItems);
        } else {
          // toast.warn("Ei suosituimpia tuotteita näytettäväksi.", {
          //   toastId: `warn-no-popular-${Date.now()}`
          // });
        }
      } else {
        throw new Error("API:n vastausrakenne ei ole odotettu.");
      }
    } catch (error) {
      // toast.error("Tietojen haku epäonnistui. Yritä myöhemmin uudelleen.", {
      //   toastId: `error-fetch-popular-${Date.now()}`
      // });
    }
  };

  const addToCart = (item) => {
    try {
      const quantityToAdd = selectedQuantities[item.id] || 1;

      // Tarkista, onko tuotetta riittävästi varastossa
      if (quantityToAdd > Number(item.available)) {
        // toast.warn(`Tuotetta ${item.name} ei voi lisätä enempää koriin, varastossa ${item.available} kpl.`, {
        //   toastId: `warn-add-${item.id}-${Date.now()}`
        // });
        return;
      }

      const cart = [...cartItems];
      const existingItem = cart.find((cartItem) => cartItem.id === item.id);

      if (existingItem) {
        if (existingItem.quantity + quantityToAdd > Number(item.available)) {
          // toast.warn(`Tuotetta ${item.name} ei voi lisätä enempää koriin, varastossa ${item.available} kpl.`, {
          //   toastId: `warn-add-existing-${item.id}-${Date.now()}`
          // });
          return;
        }
        existingItem.quantity += quantityToAdd;
      } else {
        cart.push({ ...item, quantity: quantityToAdd, category: item.category || "MostPopular" });
      }

      // Tallenna päivitetty ostoskori localStorageen
      localStorage.setItem("rentalCart", JSON.stringify(cart));

      // Toast-viesti, lisää uniikki toastId
      // toast.success(`${quantityToAdd} kpl ${item.name} lisätty vuokrauskoriin!`, {
      //   toastId: `add-${item.id}-${Date.now()}`
      // });

      // Päivitä cartItems state
      setCartItems(cart);

      // Ilmoita muille komponenteille, että ostoskori on päivitetty
      window.dispatchEvent(new Event("cartUpdated"));
    } catch (error) {
      // toast.error("Tuotteen lisääminen koriin epäonnistui. Yritä myöhemmin.", {
      //   toastId: `error-add-${Date.now()}`
      // });
    }
  };

  // Käsittelee määrän lisäämisen
  const incrementQuantity = (productId, available) => {
    setSelectedQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 1;
      if (currentQuantity < available) {
        return { ...prevQuantities, [productId]: currentQuantity + 1 };
      }
      return prevQuantities;
    });
  };

  // Käsittelee määrän vähentämisen
  const decrementQuantity = (productId) => {
    setSelectedQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 1;
      if (currentQuantity > 1) {
        return { ...prevQuantities, [productId]: currentQuantity - 1 };
      }
      return prevQuantities;
    });
  };

  // Apufunktiot ostoskorin hallintaan
  const isProductInCart = (productId) => {
    return cartItems.some((item) => item.id === productId);
  };

  const getProductQuantityInCart = (productId) => {
    return cartItems
      .filter((item) => item.id === productId)
      .reduce((sum, item) => sum + item.quantity, 0);
  };

  useEffect(() => {
    // Alusta AOS
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });

    fetchMostPopular();

    // Aseta polling: hae tiedot joka 10 sekunti
    const interval = setInterval(() => {
      fetchMostPopular();
    }, 10000); // 10000 ms = 10 sekuntia

    // Kuuntele ostoskorin päivityksiä
    const handleCartUpdate = () => {
      const newCart = getCart();
      setCartItems(newCart);
    };

    window.addEventListener("cartUpdated", handleCartUpdate);

    // Puhdista interval ja tapahtumankuuntelija kun komponentti poistuu
    return () => {
      clearInterval(interval);
      window.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, []); // Poistettu cartItems riippuvuus

  useEffect(() => {
    // console.log("Most Popular Data:", mostPopular);
  }, [mostPopular]);

  useEffect(() => {
    // console.log("Cart Items:", cartItems);
  }, [cartItems]);

  return (
    <div
      data-aos="fade-right"
      className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-4 pl-5 pr-5 rounded-3xl cursor-pointer p-5 container min-h-[620px] flex"
    >
      <div className="container pb-10 pl-5 pr-5">
        {/* Sivun otsikko */}
        <div className="flex flex-row items-center">
          <h2
            className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight"
            aria-label="Vuokratuimmat laitteet"
          >
            VUOKRATUIMMAT
          </h2>
          <div className="speech-title ml-5 flex justify-center">
            {/* <TextToSpeech text={text} /> */} {/* Kommentoitu pois */}
          </div>
        </div>

        {/* Sivun kuvaus */}
        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
          Tutustu suosituimpiin ja vuokratuimpiin laitteisiimme. Näiden laadukkaiden tuotteiden avulla varmistat onnistuneen tapahtuman, olipa kyseessä bileet, teemabileet tai muu erityistilaisuus.
        </p>

        {/* Lista vuokrattavista */}
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {mostPopular.map((item) => {
              const isInCart = isProductInCart(item.id); // Tarkista, onko tuote jo ostoskorissa
              const quantityInCart = getProductQuantityInCart(item.id);
              const availableTotal = Number(item.available) - quantityInCart; // Laske saatavilla oleva määrä

              return (
                <div
                  key={item.id}
                  data-aos="fade-up"
                  className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between"
                >
                  {/* <TextToSpeech text={item.description || "Lisätietoja ei ole saatavilla"} /> */} {/* Kommentoitu pois */}
                  {/* Tuotteen kuva */}
                  <div className="w-full h-[120px]">
                    <Link
                      to={item.link || "#"}
                      title={item.name}
                      onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                      }}
                      aria-label={item.name}
                    >
                      <img
                        src={item.image || "https://via.placeholder.com/150"} // Placeholder-kuva
                        alt={item.name}
                        loading="lazy"
                        title={item.name}
                        className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]"
                      />
                    </Link>
                  </div>

                  <div className="flex flex-col justify-between flex-grow">
                    <div className="grid grid-cols-1 place-items-center pt-10">
                      {/* Tuotteen nimi */}
                      <h3
                        className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary text-xl sm:text-1xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5"
                        title={item.name}
                      >
                        {item.name}
                      </h3>
                    </div>
                    <div className="mt-auto text-center">
                      <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                        <p className="specs font-customFont1 text-darkest dark:text-primary text-xl sm:text-1xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal">
                          {item.price ? `${item.price} € / 24h` : "Hinta ei saatavilla"}
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <Link
                          to={item.link || "#"}
                          title="Katso lisätiedot"
                          onClick={() => {
                            if (item.link) {
                              window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                            }
                          }}
                          aria-label={`Katso lisätiedot ${item.name}`}
                        >
                          <button
                            className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75"
                            title="Katso lisätiedot"
                          >
                            Lisätiedot
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* Lisää "Lisää koriin" -nappi ja määrävalitsin */}
                  {item.available > 1 ? (
                    <div className="grid place-content-center mt-4">
                      <div className="flex flex-col items-center space-y-2">
                        {/* Määrän valitsin */}
                        <div className="flex items-center justify-center space-x-2">
                          {/* Vähennä määrää */}
                          <button
                            onClick={() => decrementQuantity(item.id)}
                            className="h-8 w-8 rounded-full bg-darkest text-primary font-bold flex items-center justify-center hover:bg-blue-900 dark:bg-violet dark:text-primary dark:hover:bg-blue-900"
                            title="Vähennä määrää"
                          >
                            -
                          </button>

                          {/* Näytä valittu määrä */}
                          <span className="text-xl font-bold text-darkest dark:text-primary">
                            {selectedQuantities[item.id] || 1}
                          </span>

                          {/* Lisää määrää */}
                          <button
                            onClick={() => incrementQuantity(item.id, availableTotal)}
                            className="h-8 w-8 rounded-full bg-darkest text-primary font-bold flex items-center justify-center hover:bg-blue-900 dark:bg-violet dark:text-primary dark:hover:bg-blue-900"
                            title="Lisää määrää"
                          >
                            +
                          </button>
                        </div>

                        {/* Lisää koriin -nappi */}
                        <button
                          title={isInCart ? "Lisätty koriin" : "Lisää vuokrauskoriin"}
                          onClick={() => addToCart(item)}
                          disabled={isInCart || availableTotal <= 0}
                          className={`h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                            isInCart || availableTotal <= 0 ? "opacity-50 cursor-not-allowed" : ""
                          }`}
                          style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                        >
                          {isInCart ? "Lisätty koriin" : "Lisää koriin"}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center mt-4">
                      {/* Lisää koriin -nappi kaikille muille tuotteille */}
                      <button
                        title={isInCart ? "Lisätty koriin" : "Lisää vuokrauskoriin"}
                        onClick={() => addToCart(item)}
                        disabled={isInCart || item.available <= 0}
                        className={`h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                          isInCart || item.available <= 0
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                      >
                        {isInCart ? "Lisätty koriin" : "Lisää koriin"}
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="grid place-content-center mt-10">
            <Link
              to="/palvelut"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
              }}
              aria-label="Katso kaikki vuokrattavat laitteet"
            >
              <button
                title="Katso kaikki!"
                className="h-12 w-52 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-2xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer"
                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
              >
                Katso kaikki!
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopular;
