import Footer from '../../components/Footer/Footer';
import ADJStingerSpot30 from '../../components/ADJStingerSpot30/ADJStingerSpot30';
import ADJStingerSpot30HelmetComponent from './ADJStingerspotHelmetComponent';

export default function ADJ_StingerSpot30() {
    return (
        <>
            <ADJStingerSpot30HelmetComponent /> {/* Lisätty Helmet-komponentti */}
            <ADJStingerSpot30 />
            <Footer />
        </>
    );
}
