// src/components/Mackie300W/Mackie300.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images
import MackieLogo from '../../assets/Mackie_logo2.webp';
import Mackie300Image from '../../assets/PA Mackie RDF ART-300A 300W.webp';
import tmixImage from '../../assets/Thetmix1202.webp';

// Import ThemeContext
import ThemeContext from '../../context/ThemeContext';

// Helper functions for cart management
const getCart = () => {
  return JSON.parse(localStorage.getItem("rentalCart")) || [];
};

const isProductInCart = (productId) => {
  const cart = getCart();
  return cart.some((item) => item.id === productId);
};

const getProductQuantityInCart = (productId) => {
  const cart = getCart();
  return cart
      .filter((item) => item.id === productId)
      .reduce((sum, item) => sum + item.quantity, 0);
};

const Mackie300 = () => {
  const { theme } = useContext(ThemeContext); // Käytetään ThemeContext
  const [activeImg, setActiveImage] = useState(Mackie300Image);
  const [available, setAvailable] = useState(0); // Initial stock
  const [quantity, setQuantity] = useState(1);
  const productId = "4"; // ID for Mackie RCF 300W

  // Tekstit
  const text =
    "Mackie rcf kolmesataa wattinen äänentoistopaketti mikserillä. Tehokas ja kompakti äänentoistopaketti. Täydellinen setti pieneen tilaan!";

  const text2 =
    "Tämä on pienin settimme mikserillä, ja se sopii erinomaisesti seuraaviin käyttötarkoituksiin: Mackien kaiuttimet tarjoavat erinomaisen äänenlaadun ja sopivat erinomaisesti musiikin toistoon, puheiden pitoon tai pieneksi PA:ksi. Ne voidaan myös asentaa kulmaan toimimaan monitoreina. Pieni mikseri monipuolisilla liitännöillä: Mikseriin on helppo kytkeä kaikki yleisimmät musiikintoistolaitteet, kuten kannettava tietokone, tabletti, älypuhelin ja paljon muuta. Ota yhteyttä ja vuokraa tämä kompakti paketti tapahtumaasi!";

  const text3 =
    "Hinta. Alkaen sata euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

  // Fetch product availability from backend
  useEffect(() => {
      const fetchAvailability = async () => {
          try {
            const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=äänentoisto");
              if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const data = await response.json();

              if (!data.categories) {
                  throw new Error("Categories field is missing in API response");
              }

              // Combine all categories into one array
              const inventory = Object.values(data.categories).flat();

              // Find product by ID
              const currentProduct = inventory.find(item => item.id === productId);

              if (currentProduct && Object.prototype.hasOwnProperty.call(currentProduct, 'available')) {
                  const availableNumber = typeof currentProduct.available === 'number' 
                      ? currentProduct.available 
                      : parseInt(currentProduct.available, 10);
                  setAvailable(isNaN(availableNumber) ? 0 : availableNumber);

                  if (availableNumber <= 1) {
                      setQuantity(1); // Automatically set quantity to 1
                  }
              } else {
                  console.error(`Product not found with ID ${productId} or 'available' field is missing!`);
                //   toast.error("Tuotteen saatavuustiedot puuttuvat. Yritä myöhemmin uudelleen.", { position: "top-right" });
              }
          } catch (error) {
              console.error("Error fetching API data:", error);
            //   toast.error("Virhe saatavuuden hakemisessa. Yritä myöhemmin uudelleen.", { position: "top-right" });
          }
      };

      fetchAvailability();
  }, [productId]);

  // Hae tuotteen saatavuus backendistä
  useEffect(() => {
      const handleCartUpdate = () => {
          const fetchAvailability = async () => {
              try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=äänentoisto");
                  if (!response.ok) {
                      throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                  const data = await response.json();

                  if (!data.categories) {
                      throw new Error("Categories field is missing in API response");
                  }

                  const inventory = Object.values(data.categories).flat();
                  const currentProduct = inventory.find(item => item.id === productId);

                  if (currentProduct) {
                    const productStock = Number(currentProduct.available);
                    const currentQuantityInCart = getProductQuantityInCart(productId);
                    const finalAvailable = productStock - currentQuantityInCart;
                    setAvailable(Math.max(finalAvailable, 0));
                    // Adjust quantity if necessary
                    if (quantity > finalAvailable) {
                        setQuantity(Math.min(Math.max(1, finalAvailable), finalAvailable));
                    }
                } else {
                    setAvailable(0);
                }
            } catch (error) {
                console.error("Error updating availability:", error);
            }
          };

          fetchAvailability();
      };

      window.addEventListener("cartUpdated", handleCartUpdate);
      return () => {
          window.removeEventListener("cartUpdated", handleCartUpdate);
      };
    }, [productId, quantity]);

  const handleImageClick = (image) => {
      setActiveImage(image);
  };

  // Lisää toast-funktioiden kutsujen yhteyteen
const addToCart = async () => {
    try {

        // Hae tuote varastotilanne
        const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=äänentoisto");
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const inventory = Object.values(data.categories).flat();
        const currentProduct = inventory.find(item => item.id === productId);
        const productStock = Number(currentProduct?.available) || 0;

        // Hae ostoskorin sisältö
        const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
        const product = {
            id: productId,
            name: "Mackie RCF 300W äänentoistopaketti mikserillä",
            price: 100, // Hinta per päivä (€)
            quantity: quantity,
        };

        // Tarkista, onko tuote jo ostoskorissa
        const existingProduct = cart.find((item) => item.id === product.id);
        const newQuantity = existingProduct ? existingProduct.quantity + quantity : quantity;

        console.log(`newQuantity: ${newQuantity}, productStock: ${productStock}`); // Debug

        // Estä lisääminen, jos määrä ylittää saatavuuden
        if (newQuantity > productStock) {
            console.log("Yritetään lisätä enemmän kuin saatavilla"); // Debug
            // toast.error("Lisättävä määrä ylittää saatavilla olevan varaston!", { position: "top-right" });
            return;
        }

        // Lisää tai päivitä tuote ostoskoriin
        if (existingProduct) {
            existingProduct.quantity = newQuantity;
        } else {
            cart.push(product);
        }

        // Päivitä ostoskori localStorageen
        localStorage.setItem("rentalCart", JSON.stringify(cart));

        // Lähetä tapahtuma päivittääksesi ostoskorin tilan muissa komponenteissa
        window.dispatchEvent(new Event("cartUpdated"));

        // Päivitä saatavuus
        setAvailable(prevAvailable => Math.max(prevAvailable - quantity, 0));

        // Näytä onnistumisilmoitus
        console.log("Näytetään toast onnistumisilmoitus"); // Debug
        // toast.success(`${quantity} x ${product.name} lisätty vuokrauskoriin!`, {
        //     position: "bottom-left",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        // });

    } catch (error) {
        console.error("Error adding product to cart:", error);
        // toast.error("Tuotteen lisääminen vuokrauskoriin epäonnistui. Yritä myöhemmin.", { position: "top-right" });
    }
};


  return (
    <>
        {/* <ToastContainer
            toastClassName={() =>
                "relative flex items-center justify-between p-6 mb-4 w-full max-w-full dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest text-xl font-semibold rounded-lg shadow-lg border border-gray-300"
            }
        /> */}
        
      {/* Takaisin-painike */}
      <button 
        onClick={() => { window.history.back(); }} 
        className={`h-12 w-32 ${
            theme === 'dark' ? 'dark:bg-violet dark:text-primary dark:hover:border-2 dark:border-primary' : 'bg-primary text-darkest hover:border-2 border-darkest'
        } font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none`}
        aria-label="Takaisin"
      >
        Takaisin
      </button>
         
      {/* Tuotteen kuvat */}
      <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>

          <div className='flex flex-col gap-5'>

              <img 
                  src={activeImg} 
                  title="300W äänentoistopaketti" 
                  alt='300W äänentoistopaketti' 
                  className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
              />

              <div className='flex flex-row justify-center h-24 drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>

                  {/* Klikattavat kuvat korvattu button-elementeillä */}
                  <button 
                      onClick={() => handleImageClick(Mackie300Image)} 
                      className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                      aria-label="Näytä PA Mackie RDF ART-300A 300W"
                  >
                      <img 
                          src={Mackie300Image} 
                          alt="PA Mackie RDF ART-300A 300W" 
                          title="PA Mackie RDF ART-300A 300W" 
                          className='w-full h-full object-cover rounded-md' 
                      />
                  </button>

                  <button 
                      onClick={() => handleImageClick(tmixImage)} 
                      className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                      aria-label="Näytä Mikseri Thetmix1202"
                  >
                      <img 
                          src={tmixImage} 
                          alt="Mikseri Thetmix1202" 
                          title="Mikseri Thetmix1202" 
                          className='w-full h-full object-cover rounded-md' 
                      />
                  </button>

              </div>

              {/* Tuotteen videot */}
              <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
                  Videot
              </h2>

              <div className="flex gap-5 flex-wrap justify-center aspect-video">
                  <iframe
                      className="aspect-auto"
                      src="https://www.youtube.com/embed/rTkP4k3twK0?si=ih7ZY1o_M15Q34pJ"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Mackie RCF 300W äänentoistopaketti esittelyvideo"
                      aria-label="Mackie RCF 300W äänentoistopaketti esittelyvideo"
                  ></iframe>
              </div>
          </div>

          {/* Tuotteen kuvaus */}
          <div className={`dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl`}>

              <div>

                  {/* SPEECH */}

                  <div className="flex flex-row items-center">
                      {/* Otsikko */}
                      <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                          Mackie RCF 300W äänentoistopaketti mikserillä
                      </h1>
                      <div className='speech-title ml-5 flex justify-center'>
                          <TextToSpeech text={text} />
                      </div> 
                  </div>

                  <br />
                  
                  {/* Teksti */}

                  <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                      Tehokas ja kompakti äänentoistopaketti. Täydellinen setti pieneen tilaan!
                  </p>
                  <br />

                  {/* SPEECH */}

                  <div className="flex flex-row items-center">

                      <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                          Tämä on pienin settimme mikserillä, ja se sopii erinomaisesti seuraaviin käyttötarkoituksiin:
                      </p>
                      <div className='speech-title ml-5'>
                          <TextToSpeech text={text2} />
                      </div>
                  </div>

                  <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                      <li><strong>Mackien kaiuttimet</strong> tarjoavat erinomaisen äänenlaadun ja sopivat erinomaisesti musiikin toistoon, puheiden pitoon tai pieneksi PA:ksi. Ne voidaan myös asentaa kulmaan toimimaan monitoreina.</li>
                      <li><strong>Pieni mikseri monipuolisilla liitännöillä:</strong> Mikseriin on helppo kytkeä kaikki yleisimmät musiikintoistolaitteet, kuten kannettava tietokone, tabletti, älypuhelin ja paljon muuta.</li>
                  </ul>

                  <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                      Ota yhteyttä ja vuokraa tämä kompakti paketti tapahtumaasi!
                  </p>

                  <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">

                      <FacebookShareButton
                          url={"https://spiderlegsproductions.com/300w-aanentoistopaketti"}
                          className="facebook-share-button flex flex-row items-center">
                          <FacebookIcon size={32} round />
                      </FacebookShareButton>

                      <TwitterShareButton
                          url={"https://spiderlegsproductions.com/300w-aanentoistopaketti"}
                          className="twitter-share-button flex flex-row items-center ">
                          <TwitterIcon size={32} round />
                      </TwitterShareButton>

                      <WhatsappShareButton
                          url={"https://spiderlegsproductions.com/300w-aanentoistopaketti"}
                          className="whatsapp-share-button flex flex-row items-center ">
                          <WhatsappIcon size={32} round />
                          <p className='dark:text-primary text-darkest font-customFont1'></p>
                      </WhatsappShareButton>

                      <LinkedinShareButton
                          url={"https://spiderlegsproductions.com/300w-aanentoistopaketti"}
                          className="linkedin-share-button flex flex-row items-center ">
                          <LinkedinIcon size={32} round />
                          <p className='dark:text-primary text-darkest font-customFont1'></p>
                      </LinkedinShareButton>

                      <EmailShareButton
                          url={"https://spiderlegsproductions.com/300w-aanentoistopaketti"}
                          className="email-share-button flex flex-row items-center ">
                          <EmailIcon size={32} round />
                          <p className='dark:text-primary text-darkest font-customFont1'></p>
                      </EmailShareButton>

                  </div>

                  {/* SPEECH */}

                  <div className="flex flex-row items-center">
                      {/* Hinta */}
                      <h2 title="Hinta" className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>
                          Alk. 100€ / vrk
                      </h2>
                      <div className='speech-title ml-5 flex justify-center'>
                          <TextToSpeech text={text3} />
                      </div>
                  </div>

                 {/* Lisää vuokrauskoriin-nappi */}
                  <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                      <button
                          title="Lisää vuokrauskoriin"
                          onClick={addToCart}
                          className={`h-12 w-full sm:w-60 ${
                              theme === 'dark' ? 'dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest' : 'bg-darkest text-primary hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover'
                          } font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                              isProductInCart(productId) || available <= 0 ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                          style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                          disabled={isProductInCart(productId) || available <= 0}
                      >
                          {isProductInCart(productId) ? 'Lisätty koriin' : 'Lisää vuokrauskoriin'}
                      </button>

                      <Link 
                          to="/varauskalenteri" 
                          onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                          className="w-full sm:w-60"
                      >
                          <button
                              title="Siirry varaamaan"
                              className={`h-12 w-full ${
                                  theme === 'dark' ? 'dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest' : 'bg-darkest text-primary hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover'
                              } font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none`}
                              style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                          >
                              Siirry varauskalenteriin
                          </button>
                      </Link>
                  </div>

                  {/* Tuotteen logo */}
                  <div className='flex flex-wrap place-items-start pt-5'>

                      <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">

                          {/* Tuotteen logo */}

                          <a href="https://mackie.com/" aria-label="Vieraile Mackien sivuilla." title="Vieraile Mackien sivuilla.">
                              <img 
                                  src={MackieLogo}  
                                  alt="Mackie logo" 
                                  loading="lazy" 
                                  title="Vieraile Mackien sivuilla." 
                                  className='w-full h-full object-contain rounded-xl' 
                              />
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default Mackie300;
