import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types'; // Tuodaan PropTypes

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark'); // Oletusteema

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Lisätään PropTypes-validointi
ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired, // children tulee olla node-tyyppi ja pakollinen
};

export default ThemeContext;
